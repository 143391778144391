import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "contexts/AuthContext";
import Spinner from "react-bootstrap/Spinner";

const ProtectedRoute: React.FC = () => {
    const { isAuthenticated, loading } = useAuth();

    if (loading) {
        return (
            <div className='spinner-wrapper'>
                <Spinner animation="border" role="status"/>
            </div>
        )
    }

    return isAuthenticated ? <Outlet/> : <Navigate to="/login" replace/>;
};

export default ProtectedRoute;
