import React from "react";
import Navbar from "./Navbar";
import { useAuth } from "contexts/AuthContext";
import WelcomePageNavbar from "components/WelcomePageNavbar";
import Spinner from "react-bootstrap/Spinner";

const Layout: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const { isAuthenticated, loading } = useAuth();

    if (loading) {
        return (
            <div className='spinner-wrapper'>
                <Spinner animation="border" role="status"/>
            </div>
        )
    }

    return (
        <div className="main-layout">
            <div className="main-content">
                {isAuthenticated ?
                    <>
                        <header className='navbar navbar-expand navbar-light navbar-bg w-100'>
                            <Navbar/>
                        </header>
                        <main className='auth-page-content'>
                            {children}
                        </main>
                    </> :
                    <>
                        <header className='navbar welcome-navbar navbar-expand navbar-light w-100'>
                            <WelcomePageNavbar/>
                        </header>
                        <main className=''>
                            {children}
                        </main>
                    </>
                }
            </div>
        </div>
    );
};

export default Layout;
