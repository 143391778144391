import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import crossedEyeIcon from 'assets/svg/crossed-eye.svg';
import eyeIcon from 'assets/svg/eye.svg';
import { useAuth } from "contexts/AuthContext";
import Error from "components/Error";
import * as Sentry from "@sentry/react";

const LoginPage = () => {
    const [type, setType] = useState('password');
    const [icon, setIcon] = useState(crossedEyeIcon);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const navigate = useNavigate();
    const { handleLogin, isAuthenticated } = useAuth();

    const togglePasswordVisibility = () => {
        if (type === 'password') {
            setIcon(eyeIcon);
            setType('text');
        } else {
            setIcon(crossedEyeIcon);
            setType('password')
        }
    }

    const onLoginClick = async (e: React.FormEvent) => {
        e.preventDefault();
        const data = {
            username,
            password
        }
        try {
            await handleLogin(data);
            navigate('/portfolio/instant');
        } catch (error: any) {
            Sentry.captureException(error);
            setError(error.response.data.attributes.fullMessage);
        }
    }

    return (
        <div className='form-container d-flex vh-100 align-items-center justify-content-center'>
            <Error error={error}/>
            <Form onSubmit={onLoginClick} className='login-form form w-100'>
                <h3 className='text-center mb-2'>Sign In</h3>
                <Form.Group className="mb-3 position-relative">
                    <Form.Control required onChange={(e) => setUsername(e.target.value)} value={username} className='login-form__input' type="text" placeholder=' ' />
                    <Form.Label className='login-form__label position-absolute' column='sm'>Username</Form.Label>
                </Form.Group>
                <Form.Group className="mb-1 position-relative">
                    <Form.Control required onChange={(e) => setPassword(e.target.value)} value={password} className='login-form__input' type={type} placeholder=' ' />
                    <Form.Label className='login-form__label position-absolute' column='sm'>Password</Form.Label>
                    <img onClick={togglePasswordVisibility} className='login-form__crossed-eye position-absolute' width={15} src={icon} alt="Crossed eye" />
                </Form.Group>
                <p className='login-form__forgot-pass-text'>Forgot Password</p>
                <button className="button btn btn-primary d-flex align-items-center w-100 justify-content-center" >Sign in</button>
                <span className='login-form__buttons-separator-text'>or</span>
                <button className='google-auth-btn btn btn-primary d-flex align-items-center w-100 justify-content-center'>Sign in with Google</button>
                <div className='login-form__footer d-flex justify-content-center mt-4'>
                    <span>Don't have an account yet? <Link className='login-form__signup-link' to='/signup'>Sign up</Link></span>
                </div>
            </Form>
        </div>
    )
}

export default LoginPage;
