import React from "react";
import { Helmet } from 'react-helmet-async';
import Spinner from 'react-bootstrap/Spinner';
import { getPortfolioInstant } from "api/apis";
import { appConstants } from "constants/app.constants";
import BalanceTable from "components/BalanceTable";
import Error from "components/Error";
import ExchangeError from "components/ExchangeError";
import stringUtils from "utils/stringUtils";
import TableControlsPanel from "components/TableControlsPanel";
import useTableConfigsData from "hooks/useTableConfigsData";

const PortfolioInstantPage: React.FC = () => {
    const title = stringUtils.getHtmlTitle(appConstants.TITLES.PORTFOLIO_INSTANT);

    const {
        selectedExchangeBalanceData,
        loading,
        columns,
        selectedColumns,
        selectedComponentId,
        sortOption,
        hideSmallAssets,
        error,
        selectedCurrency,
        handleInputChange,
        handleCheckboxChange,
        handleSortOptionChange,
        handleHideSmallAssetsChange,
        exchangesOptions,
    } = useTableConfigsData(getPortfolioInstant);

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            {loading && (
                <div className='spinner-wrapper'>
                    <Spinner animation="border" role="status"/>
                </div>
            )}
            <div className={loading ? "blur table-container" : "table-container px-2"}>
                <Error error={error} />
                <ExchangeError balances={selectedExchangeBalanceData} />
                <TableControlsPanel
                    exchangesOptions={exchangesOptions}
                    selectedComponentId={selectedComponentId}
                    columns={columns}
                    selectedColumns={selectedColumns}
                    sortOption={sortOption}
                    hideSmallAssets={hideSmallAssets}
                    handleInputChange={handleInputChange}
                    handleCheckboxChange={handleCheckboxChange}
                    handleSortOptionChange={handleSortOptionChange}
                    handleHideSmallAssetsChange={handleHideSmallAssetsChange}
                />
                <div className='position-relative'>
                    <div>
                        {selectedExchangeBalanceData ? (
                            <BalanceTable
                                balanceTable={selectedExchangeBalanceData}
                                selectedCurrency={selectedCurrency}
                                selectedComponentId={selectedComponentId?.value || ""}
                                columns={selectedColumns}
                            />
                        ) : (
                            <p className='empty-state'>No data available</p>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default PortfolioInstantPage;
