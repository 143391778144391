import { StepType, TourProvider } from '@reactour/tour';
import steps from 'configs/onboarding-steps.json';
import React, { Dispatch, JSX, ReactNode, SetStateAction } from 'react';
import { useNavigate } from "react-router-dom";

interface PrevButtonProps {
    currentStep: number;
    setCurrentStep: Dispatch<SetStateAction<number>>;
    steps?: StepType[];
}

interface NextButtonProps {
    currentStep: number;
    setCurrentStep: Dispatch<SetStateAction<number>>;
    steps?: StepType[];
    stepsLength: number;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const styles = {
    maskWrapper: (base: any) => ({
        ...base,
        color: '#6c757d',
    }),
    popover: (base: any) => ({
        ...base,
        borderRadius: '4px'
    }),
    badge: (base: any) => ({ ...base, backgroundColor: 'black', color: 'white' }),
    dot: (base: any) => ({ display: 'none' }),
    controls: (base: any) => ({ ...base, justifyContent: 'end', gap: '5px' }),
};

const Close = ({ onClick }: { onClick?: React.MouseEventHandler<HTMLButtonElement> }) => {
    return (
        <button
            className="btn button skip-button"
            onClick={onClick}
            style={{ position: 'absolute', left: 30, bottom: 24 }}
        >
            Skip
        </button>
    );
};

const PrevButton = ({ currentStep, setCurrentStep, steps }: PrevButtonProps): ReactNode => {
    const onClick = () => {
        const first = currentStep === 0;
        if (first && steps) {
            setCurrentStep(() => steps.length - 1);
        } else {
            setCurrentStep((s) => s - 1);
        }
    };
    return (
        <button disabled={currentStep === 0} className="btn button back-button" onClick={onClick}>
            Back
        </button>
    );
};

const NextButton = ({ currentStep, stepsLength, setIsOpen, setCurrentStep, steps }: NextButtonProps): ReactNode => {
    const navigate = useNavigate();
    const last = currentStep === stepsLength - 1;
    const onClick = () => {
        if (currentStep === 0) {
            navigate('/settings', { state: { step: currentStep + 1 } });
        } else if (last) {
            setIsOpen(false);
        } else {
            setCurrentStep((s) => (steps && s === steps.length - 1 ? 0 : s + 1));
        }
    };
    return (
        <button className="btn button next-button" onClick={onClick}>
            {last ? 'Done' : 'Next'}
        </button>
    );
};

interface TourManagerProps {
    children: ReactNode;
}

const TourManager = ({ children }: TourManagerProps): JSX.Element => {
    return (
        <TourProvider
            prevButton={PrevButton}
            nextButton={NextButton}
            components={{ Close }}
            styles={styles}
            steps={steps}
        >
            {children}
        </TourProvider>
    );
};

export default TourManager;
