// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    margin: 0;
}

button {
    border: none;
    background-color: transparent;
    cursor: pointer;
}

a {
    color: inherit;
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
p {
    margin: 0;
}

ul {
    padding-left: 0;
    list-style-type: none;
}

img {
    display: block;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/foundation/reset.css"],"names":[],"mappings":"AAAA;IACI,SAAS;AACb;;AAEA;IACI,YAAY;IACZ,6BAA6B;IAC7B,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB;;AAEA;;;;;;;;IAQI,SAAS;AACb;;AAEA;IACI,eAAe;IACf,qBAAqB;AACzB;;AAEA;IACI,cAAc;AAClB","sourcesContent":["body {\n    margin: 0;\n}\n\nbutton {\n    border: none;\n    background-color: transparent;\n    cursor: pointer;\n}\n\na {\n    color: inherit;\n    text-decoration: none;\n}\n\nh1,\nh2,\nh3,\nh4,\nh5,\nh6,\nul,\np {\n    margin: 0;\n}\n\nul {\n    padding-left: 0;\n    list-style-type: none;\n}\n\nimg {\n    display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
