import React, { JSX, useContext, useEffect, useState } from "react";
import packageJSON from "../../package.json";
import {Link, useNavigate} from "react-router-dom";
import Select, { components } from "react-select";
import { BsCardList } from "react-icons/bs";
import { TbCircuitAmmeter, TbReload } from "react-icons/tb";
import { RxAvatar } from "react-icons/rx";
import { CiSettings, CiViewBoard } from "react-icons/ci";
import { FiUser } from "react-icons/fi";
import { IoLogOutOutline, IoRocketOutline } from "react-icons/io5";
import { MdDescription, MdOutlinePolicy } from "react-icons/md";
import dropdowns from 'configs/navbar.json';
import { appConstants } from "constants/app.constants";
import CurrencyContext from "contexts/CurrencyContext";
import { getCurrencyRates } from "api/apis";
import moment from "moment";
import * as Sentry from "@sentry/react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { isWithinLastFifteenMinutes } from "utils/timeUtils";
import Spinner from "react-bootstrap/Spinner";
import { useAuth } from "contexts/AuthContext";
import { useTour } from "@reactour/tour";

const iconMap: { [key: string]: JSX.Element } = {
    BsCardList: <BsCardList />,
    TbCircuitAmmeter: <TbCircuitAmmeter />,
    CiViewBoard: <CiViewBoard />,
    IoRocketOutline: <IoRocketOutline />,
    CiSettings: <CiSettings />
};

interface CurrencyRate {
    currencyPair: string,
    price: string,
    timestamp: number
}

interface CurrencyRates {
    [key: string]: CurrencyRate[];
}

const Navbar = () => {
    const { selectedCurrency, setSelectedCurrency } = useContext(CurrencyContext);
    const [profileDropdownOpen, setProfileDropdownOpen] = useState(false);
    const [activeDropdown, setActiveDropdown] = useState<string | null>(null);
    const [currencyRates, setCurrencyRates] = useState<CurrencyRates>({});
    const [defaultRate, setDefaultRate] = useState<CurrencyRate | null>(null);
    const [widgetVisible, setWidgetVisible] = useState(false);
    const [currencyRatesLoading, setCurrencyRatesLoading] = useState(false);

    const { handleLogout } = useAuth();
    const navigate = useNavigate();
    const { setIsOpen } = useTour()

    useEffect(() => {
        // TODO[YY] add onboard attribute to backend
        const hasSeenTour = localStorage.getItem('tourCompleted');
        if (!hasSeenTour) {
            setIsOpen(true);
            localStorage.setItem('tourCompleted', 'true');
        }
    }, [setIsOpen]);

    let timestamp = currencyRates[selectedCurrency]?.[0]?.timestamp || 0;
    const isDisabled = timestamp > 0 && isWithinLastFifteenMinutes(timestamp);

    useEffect(() => {
        async function fetchCurrencyRates() {
            try {
                const rates: CurrencyRates = await getCurrencyRates();
                setCurrencyRates(rates);
                const foundRate = rates[selectedCurrency].find(rate =>
                    rate.currencyPair === `${selectedCurrency === 'USDT' ? 'USDT/USD' : `${selectedCurrency}/USDT`}`
                );
                if (foundRate) {
                    setDefaultRate(foundRate);
                }
            } catch (error) {
                Sentry.captureException(error);
            }
        }

        fetchCurrencyRates();
    }, [selectedCurrency]);

    const reloadCurrencyRates = async () => {
        try {
            setCurrencyRatesLoading(true);
            const rates = await getCurrencyRates(true);
            setCurrencyRates(rates);
        } catch (error) {
            Sentry.captureException(error);
        } finally {
            setCurrencyRatesLoading(false);
        }
    };

    const toggleProfileDropdown = () => {
        setProfileDropdownOpen(!profileDropdownOpen)
    };

    const handleMouseEnter = (key: string) => {
        setActiveDropdown(key);
    };

    const handleMouseLeave = () => {
        setActiveDropdown(null);
    };

    const handleCurrencyChange = (selected: any) => {
        setSelectedCurrency(selected.value);
    };

    const Option = (props: any) => (
        <components.Option {...props}>
            {props.data.icon} {props.data.label}
        </components.Option>
    );

    const SingleValue = (props: any) => (
        <components.SingleValue {...props}>
            <div>
                <span className="icon-wrapper">{props.data.icon}</span>
                <span>{props.data.label}</span>
            </div>
        </components.SingleValue>
    );

    const onLogoutClick = () => {
        handleLogout();
        navigate('/login');
    }

    return (
        <nav className="d-flex w-100 justify-content-between">
            <div className="d-flex align-items-center justify-content-between w-100">
                <div className="navbar__dropdowns-container d-flex align-items-center justify-content-between">
                    {dropdowns.map((dropdown) => (
                        <div
                            key={dropdown.dropdownKey}
                            className={`${dropdown.tourSelector} d-flex align-items-center nav-item position-relative dropdown-toggle dropdowns`}
                            onMouseEnter={() => handleMouseEnter(dropdown.dropdownKey)}
                            onMouseLeave={handleMouseLeave}
                        >
                            <span>{dropdown.label}</span>
                            <div
                                className={`dropdown-menu dropdown-menu-end ${
                                    activeDropdown === dropdown.dropdownKey ? 'show' : ''
                                }`}
                            >
                                {dropdown.items.map((item) => (
                                    <Link key={item.label} className="d-flex align-items-center dropdown-item"
                                          to={item.to}>
                                        <span className="dropdown-icon">{iconMap[item.icon]}</span>
                                        {item.label}
                                    </Link>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="d-flex align-items-center position-relative">
                    <div className='widget-wrapper'
                         onMouseEnter={() => setWidgetVisible(true)}
                         onMouseLeave={() => setWidgetVisible(false)}
                    >
                        <div className='default-rate__container'>
                            <span className="default-rate__pair me-2">{defaultRate?.currencyPair}</span>
                            <span className="default-rate__price me-4">{defaultRate?.price}</span>
                        </div>
                        {widgetVisible &&
                            <div className="currency-rates-widget">
                                {currencyRatesLoading && (
                                    <div className='spinner-wrapper'>
                                        <Spinner animation="border" role="status"/>
                                    </div>
                                )}
                                    <table className="table rates-table">
                                        <thead>
                                        <tr>
                                            {currencyRates[selectedCurrency]?.map((rate, index) => (
                                                <th key={index} className="rates-table__column">
                                                    {rate.currencyPair}
                                                </th>
                                            ))}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            {currencyRates[selectedCurrency]?.map((rate, index) => (
                                                <td className="rates-table__row" key={index}>
                                                    <div className="d-flex flex-column">
                                                        <span className="rates-table__price">{rate.price}</span>
                                                        <span className="rates-table__update">
                                                            {moment(rate.timestamp).fromNow()}
                                                        </span>
                                                    </div>
                                                </td>
                                            ))}
                                        </tr>
                                        </tbody>
                                    </table>
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip>
                                            {isDisabled
                                                ? 'Currency rates are continuously auto-updating. Manual refresh is available if no updates occur for 15 minutes.'
                                                : 'Reload currency rates'}
                                        </Tooltip>
                                    }
                                >
                                    <span className='reload-btn-wrapper'>
                                        <TbReload
                                            onClick={reloadCurrencyRates}
                                            className={isDisabled ? 'reload-btn disabled' : 'reload-btn'}
                                        />
                                    </span>
                                </OverlayTrigger>
                            </div>
                        }
                    </div>
                    <Select
                        className="select"
                        options={appConstants.CURRENCY_OPTIONS}
                        onChange={handleCurrencyChange}
                        placeholder="Select currency"
                        defaultValue={appConstants.CURRENCY_OPTIONS.find(option => option.value === selectedCurrency)}
                        components={{Option, SingleValue}}
                        classNamePrefix="react-select"
                    />
                    <RxAvatar className='profile-icon' onClick={toggleProfileDropdown}/>
                    <div className="d-flex align-items-center nav-item dropdown "
                    >
                        <div className={`dropdown-menu dropdown-menu-end ${profileDropdownOpen ? 'show' : ''}`}>
                            <Link className="d-flex align-items-center dropdown-item" to="/profile">
                                <FiUser className="dropdown-icon"/>Profile
                            </Link>
                            <Link className="d-flex align-items-center dropdown-item" to="/terms-of-services">
                                <MdDescription className="dropdown-icon"/>Terms of Services
                            </Link>
                            <Link className="d-flex align-items-center dropdown-item" to="/privacy-policy">
                                <MdOutlinePolicy className="dropdown-icon"/>Privacy Policy
                            </Link>
                            <div className="dropdown-divider"></div>
                            <p className="app-version">© {moment().year()} {appConstants.NAME.CAMELCASE}, v{packageJSON.version}</p>
                            <div className="dropdown-divider"></div>
                            <span onClick={onLogoutClick} className="d-flex align-items-center dropdown-item">
                                <IoLogOutOutline className="dropdown-icon"/>
                                Log out
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
