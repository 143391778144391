import axios from 'axios';

const axiosClient = axios.create({
    baseURL: 'http://localhost:7776/',
    withCredentials: true
});

export const fakeServerAxiosClient = axios.create({
    baseURL: 'http://localhost:7123/v1/ccxtrader'
});

axiosClient.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            if (originalRequest.url.includes("/refreshToken")) {
                return Promise.reject(error);
            }

            try {
                await axiosClient.post("/jbst/security/authentication/refreshToken");
                return axiosClient(originalRequest);
            } catch (refreshError) {
                return Promise.reject(refreshError);
            }
        }

        return Promise.reject(error);
    }
);

export default axiosClient;
