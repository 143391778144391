import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import notFoundImage from 'assets/images/404.svg';

const NotFound: React.FC = () => {
    const navigate = useNavigate();

    const handleGoHome = () => {
        navigate('/');
    };

    return (
        <Container className="d-flex vh-100 align-items-center justify-content-center">
            <Row className="w-100 justify-content-center text-center">
                <Col xs={12} md={8} lg={6}>
                    <div className="mb-4">
                        <img
                            src={notFoundImage}
                            alt="404 Error"
                            className="not-found-img img-fluid"
                        />
                    </div>
                    <h1 className="display-1 mb-4">404</h1>
                    <h2 className="h3 mb-4">Oops! Page Not Found</h2>
                    <p className="text-muted mb-4">
                        The page you are looking for might have been removed, had its name changed,
                        or is temporarily unavailable.
                    </p>
                    <button
                        onClick={handleGoHome}
                        className="px-4 button btn btn-primary"
                    >
                        Back To Home
                    </button>
                </Col>
            </Row>
        </Container>
    );
};

export default NotFound;
