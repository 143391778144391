import React from "react";
import profileIcon from 'assets/svg/profile.svg';
import verifyIcon from 'assets/svg/verify.svg';
import walletIcon from 'assets/svg/wallet.svg';
import tradeIcon from 'assets/svg/trade.svg';
import moment from "moment/moment";
import {appConstants} from "../constants/app.constants";

const WelcomePage = () => {
    return (
        <>
            <section className='hero d-flex align-items-center'>
                <div className='hero__text-container position-relative'>
                    <div className='hero__paragraph-container'>
                        <h1 className="hero__title mb-3">
                          <span className="line line-1">
                            Your <span className="accent-color">All-in-One Crypto</span>
                          </span>
                            <span className="line line-2"> Trading Platform.</span>
                        </h1>
                    </div>
                    <p className='hero__paragraph mb-4'>Track your balances, analyze performance,
                        and <br/> trade effortlessly with advanced
                        tools.</p>
                    <button className="hero__button floating button btn btn-primary d-flex align-items-center">Get
                        Started
                        for Free
                    </button>
                </div>
            </section>
            <section className='get-started-section flex-column'>
                <div className='get-started'>
                    <div className='get-started__text-container'>
                        <h2 className='get-started__title'>Get Started in Just a <span
                            className="accent-color">Few Steps</span></h2>
                        <p className='get-started__paragraph'>Join CCEXTrader and experience the easiest way to trade
                            across
                            Spot, Margin, Futures, and more.</p>
                        <button className='button get-started__button floating btn btn-primary mt-4'>Start Trading
                        </button>
                    </div>
                    <ul className='get-started__list'>
                        <li className='get-started__list-item'>
                            <img className='get-started__list-item-icon' src={profileIcon} alt="Profile"/>
                            <h3 className='get-started__list-item-title'>Create an Account</h3>
                            <p className='get-started__list-item-text'>Sign up in just a few minutes</p>
                        </li>
                        <li className='get-started__list-item'>
                            <img className='get-started__list-item-icon' src={verifyIcon} alt="Shield"/>
                            <h3 className='get-started__list-item-title'>Verify Your Account</h3>
                            <p className='get-started__list-item-text'>Secure your account with our simple verification
                                process.</p>
                        </li>
                        <li className='get-started__list-item'>
                            <img className='get-started__list-item-icon' src={walletIcon} alt="Wallet"/>
                            <h3 className='get-started__list-item-title'>Fund Your Wallet</h3>
                            <p className='get-started__list-item-text'>Add funds easily through our secure wallet.</p>
                        </li>
                        <li className='get-started__list-item'>
                            <img className='get-started__list-item-icon' src={tradeIcon} alt="Trade"/>
                            <h3 className='get-started__list-item-title'>Start Trading</h3>
                            <p className='get-started__list-item-text'>Buy, sell, and manage your trades instantly.</p>
                        </li>
                    </ul>
                </div>
            </section>
            <section className='join'>
                <div className='join-container floating'>
                    <h3 className='join-container__title'>Trade Smarter, Not Harder: Your All-in-One Platform
                        Awaits</h3>
                    <button className="join-container__button button btn btn-primary d-flex align-items-center">Get
                        Started
                    </button>
                </div>
            </section>
            <div className='footer-container-v2'>
                <div className='footer d-flex justify-content-between p-2'>
                    <span>Copyright © {moment().year()} {appConstants.NAME.CAMELCASE}</span>
                    <div>
                        <span>All Rights Reserved | </span>
                        <a href='/terms-of-services'>Terms of Services | </a>
                        <a href='/privacy-policy'>Privacy Policy</a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WelcomePage;
