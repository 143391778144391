import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import eyeIcon from "assets/svg/eye.svg";
import crossedEyeIcon from "assets/svg/crossed-eye.svg";
import { timezoneConstants } from "constants/timezone.constants";
import { useAuth } from "contexts/AuthContext";
import Error from "components/Error";

const validationSchema = yup.object().shape({
    username: yup.string().required("Username is required"),
    email: yup.string().email("Invalid email address").required("Email is required"),
    password: yup
        .string().required("Password is required")
        .min(8, "Password must be at least 8 characters")
        .max(20, "Password must not exceed 20 characters")
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,20}$/,
            "Password must contain at least one uppercase letter, one lowercase letter, and one number"
        ),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref("password")], "Passwords must match")
        .required("Confirm password is required"),
    zoneId: yup.object().nullable().required("Timezone is required"),
});

const RegistrationPage = () => {
    const [passwordType, setPasswordType] = useState('password');
    const [confirmPasswordType, setConfirmPasswordType] = useState('password');
    const [passwordIcon, setPasswordIcon] = useState(crossedEyeIcon);
    const [confirmPasswordIcon, setConfirmPasswordIcon] = useState(crossedEyeIcon);
    const [error, setError] = useState('');

    const { handleSignUp } = useAuth();
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        control,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            zoneId: {value: 'Europe/Kyiv', label: '(+03:00) Europe/Kyiv'}
        }
    });


    const onSubmit = async (data: any) => {
        try {
            setError('');
            const signUpData = {
                ...data,
                zoneId: data.zoneId.value
            };
            await handleSignUp(signUpData);
            navigate('/login');
        } catch (error: any) {
            Sentry.captureException(error);
            setError(error.message);
        }
    };

    const togglePasswordVisibility = () => {
        if (passwordType === 'password') {
            setPasswordIcon(eyeIcon);
            setPasswordType('text');
        } else {
            setPasswordIcon(crossedEyeIcon);
            setPasswordType('password');
        }
    }

    const toggleConfirmPasswordVisibility = () => {
        if (confirmPasswordType === 'password') {
            setConfirmPasswordIcon(eyeIcon);
            setConfirmPasswordType('text');
        } else {
            setConfirmPasswordIcon(crossedEyeIcon);
            setConfirmPasswordType('password');
        }
    }

    return (
        <div className='form-container vh-100 d-flex align-items-center justify-content-center'>
            <Error error={error}/>
            <Form className="form signup-form w-100" onSubmit={handleSubmit(onSubmit)}>
                <h3 className="text-center mb-2">Sign Up</h3>
                <Form.Group className="mb-3">
                    <div className="position-relative">
                        <Form.Control
                            {...register("email")}
                            className="login-form__input"
                            type="email"
                            placeholder=" "
                            isInvalid={!!errors.email}
                        />
                        <Form.Label className="login-form__label position-absolute" column="sm">
                            Email address
                        </Form.Label>
                    </div>
                    {errors.email && (
                        <div className="invalid-feedback d-block">{errors.email.message}</div>
                    )}
                </Form.Group>
                <Form.Group className='mb-3'>
                    <div className="position-relative">
                        <Form.Control
                            {...register("username")}
                            className="login-form__input"
                            type="text"
                            placeholder=" "
                            isInvalid={!!errors.username}
                        />
                        <Form.Label className="login-form__label position-absolute" column="sm">
                            Username
                        </Form.Label>
                    </div>
                    {errors.username && (
                        <div className="invalid-feedback d-block">{errors.username.message}</div>
                    )}
                </Form.Group>
                <Form.Group className="mb-3">
                    <div className='position-relative'>
                        <Form.Control
                            {...register("password")}
                            className="login-form__input"
                            type={passwordType}
                            placeholder=" "
                            isInvalid={!!errors.password}
                        />
                        <Form.Label className="login-form__label position-absolute" column="sm">
                            Password
                        </Form.Label>
                        <img onClick={togglePasswordVisibility} className='login-form__crossed-eye position-absolute'
                             width='15' src={passwordIcon} alt="Crossed eye"/>
                    </div>
                    {errors.password && (
                        <div className="invalid-feedback d-block">{errors.password.message}</div>
                    )}
                </Form.Group>
                <Form.Group className="mb-3">
                    <div className='position-relative'>
                        <Form.Control
                            {...register("confirmPassword")}
                            className="login-form__input"
                            type={confirmPasswordType}
                            placeholder=" "
                            isInvalid={!!errors.confirmPassword}
                        />
                        <Form.Label className="login-form__label position-absolute" column="sm">
                            Confirm password
                        </Form.Label>
                        <img onClick={toggleConfirmPasswordVisibility}
                             className='login-form__crossed-eye position-absolute'
                             width='15' src={confirmPasswordIcon} alt="Crossed eye"/>
                    </div>
                    {errors.confirmPassword && (
                        <div className="invalid-feedback d-block">{errors.confirmPassword.message}</div>
                    )}
                </Form.Group>
                <div>
                    <Controller
                        name="zoneId"
                        control={control}
                        render={({field}) => (
                            <Select
                                {...field}
                                options={timezoneConstants}
                                defaultValue={{value: 'Europe/Kyiv', label: '(+03:00) Europe/Kyiv'}}
                                placeholder="Select timezone"
                                classNamePrefix="react-select"
                            />
                        )}
                    />
                </div>
                {errors.zoneId && (
                    <div className="invalid-feedback d-block">{errors.zoneId.message}</div>
                )}
                <button className="mt-3 button btn btn-primary d-flex align-items-center w-100 justify-content-center">
                    Sign up
                </button>
                <span className="login-form__buttons-separator-text">or</span>
                <button
                    type="button"
                    className="google-auth-btn btn btn-primary d-flex align-items-center w-100 justify-content-center"
                >
                    Sign up with Google
                </button>
                <div className='login-form__footer d-flex justify-content-center mt-4'>
                <span>Already have an account? <Link className='login-form__signup-link'
                                                     to='/login'>Sign in</Link></span>
                </div>
            </Form>
        </div>
    );
};

export default RegistrationPage;
