import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "contexts/AuthContext";
import React from "react";
import Spinner from "react-bootstrap/Spinner";

const AnonymousRoute = () => {
    const { isAuthenticated, loading } = useAuth();

    if (loading) {
        return (
            <div className='spinner-wrapper'>
                <Spinner animation="border" role="status"/>
            </div>
        )
    }

    return isAuthenticated ? <Navigate to="/portfolio/instant" replace /> : <Outlet />
}

export default AnonymousRoute;
