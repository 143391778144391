import React, { createContext, useContext, useEffect, useState } from "react";
import { signUp, login, logout, getCurrentSession } from "api/apis";

interface UserEmailDetails {
    required: boolean;
    confirmed: boolean
}

interface CurrentClientUser {
    username: string;
    email: string;
    name: string;
    zoneId: string;
    passwordChangeRequired: boolean;
    emailDetails: UserEmailDetails;
    authorities: string[];
    attributes: {};
}

interface RequestUserLogin {
    username: string;
    password: string;
}

interface RequestUserRegistration0 {
    email: string;
    username: string;
    password: string;
    confirmPassword: string;
    zoneId: string;
}

interface AuthContextType {
    user: CurrentClientUser | null;
    isAuthenticated: boolean;
    handleLogin: (data: RequestUserLogin) => void;
    handleLogout: () => void;
    handleSignUp: (data: RequestUserRegistration0) => void;
    loading: boolean;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<CurrentClientUser | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchCurrentSession = async () => {
            try {
                const response = await getCurrentSession();
                setUser(response);
            } catch (error) {
                setUser(null);
            } finally {
                setLoading(false);
            }
        };

        fetchCurrentSession();
    }, []);

    const handleSignUp = async (data: RequestUserRegistration0) => {
        const response = await signUp(data);
        if (!response.success) {
            throw new Error(response.error);
        }
    };

    const handleLogin = async (data: RequestUserLogin) => {
        const response = await login(data);
        if (!response.success) {
            throw new Error(response.error);
        }
        setUser(response.data);
    };

    const handleLogout = async () => {
        await logout();
        setUser(null);
    };

    return (
        <AuthContext.Provider value={{ user, isAuthenticated: !!user, handleLogin, handleLogout, handleSignUp, loading }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
