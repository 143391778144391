// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select {
    min-width: max-content;
    margin-right: 20px;
    height: 30px;
    font-size: 14px;
}

.signup-form .select {
    margin: 0 0 15px 0;
    height: 35px;
}

.signup-form .react-select__control,
.signup-form .react-select__control:hover {
    border-color: #dee2e6;
}

.signup-form .react-select__placeholder {
    font-size: 12px;
    color: var(--dark);
    margin-left: 15px;
}

.signup-form .react-select__control {
    height: 35px;
    min-height: 35px;
    font-size: 12px;
}

.signup-form .react-select__menu {
    font-size: 12px;
}

.select .react-select__control {
    height: 30px;
    min-height: 30px;
    line-height: 1;
}

.select .react-select__value-container {
    padding: 0 3px 0 0;
}

.react-select__input-container {
    margin: 0;
    padding: 0;
}

.select .react-select__menu {
    width: max-content;
    z-index: 1000;
    background-color: white;
}

.select .react-select__indicator {
    display: flex;
    align-items: center;
    padding: 0;
    width: 15px;
    height: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/components/select.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,YAAY;AAChB;;AAEA;;IAEI,qBAAqB;AACzB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,SAAS;IACT,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,UAAU;IACV,WAAW;IACX,YAAY;AAChB","sourcesContent":[".select {\n    min-width: max-content;\n    margin-right: 20px;\n    height: 30px;\n    font-size: 14px;\n}\n\n.signup-form .select {\n    margin: 0 0 15px 0;\n    height: 35px;\n}\n\n.signup-form .react-select__control,\n.signup-form .react-select__control:hover {\n    border-color: #dee2e6;\n}\n\n.signup-form .react-select__placeholder {\n    font-size: 12px;\n    color: var(--dark);\n    margin-left: 15px;\n}\n\n.signup-form .react-select__control {\n    height: 35px;\n    min-height: 35px;\n    font-size: 12px;\n}\n\n.signup-form .react-select__menu {\n    font-size: 12px;\n}\n\n.select .react-select__control {\n    height: 30px;\n    min-height: 30px;\n    line-height: 1;\n}\n\n.select .react-select__value-container {\n    padding: 0 3px 0 0;\n}\n\n.react-select__input-container {\n    margin: 0;\n    padding: 0;\n}\n\n.select .react-select__menu {\n    width: max-content;\n    z-index: 1000;\n    background-color: white;\n}\n\n.select .react-select__indicator {\n    display: flex;\n    align-items: center;\n    padding: 0;\n    width: 15px;\n    height: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
