import React, { useState } from "react";
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import { IoBarChartOutline } from "react-icons/io5";
import { PiBookOpenTextLight } from "react-icons/pi";
import OrderBook from "components/OrderBook";
import stringUtils from "utils/stringUtils";
import { appConstants } from "constants/app.constants";
import { Helmet } from "react-helmet-async";

const TradeBasicPage = () => {
    const[isOpen, setIsOpen] = useState(false);

    const title = stringUtils.getHtmlTitle(appConstants.TITLES.TRADE_BASIC);

    const toggleOrderBookAccordion = () => {
        setIsOpen(!isOpen);
    }

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <div className="trade-basic px-2">
                <div className="d-flex">
                    <Accordion className="accordion-orderbook me-2" onClick={toggleOrderBookAccordion}>
                        <Accordion.Item eventKey="0">
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>{isOpen ? 'Close Orderbook' : 'Open Orderbook'}</Tooltip>}
                            >
                                <Accordion.Header>
                                    <PiBookOpenTextLight />
                                </Accordion.Header>
                            </OverlayTrigger>
                            <Accordion.Body>
                                <OrderBook />
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <div className="w-100">
                        <Accordion className="mb-2 accordion-chart">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header> <IoBarChartOutline /></Accordion.Header>
                                <Accordion.Body>
                                    <div style={{height: 500}}>
                                        <AdvancedRealTimeChart symbol='BTC' theme="light"
                                                               autosize></AdvancedRealTimeChart>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <div className="trade-basic__order-form d-flex gap-3 bg-white p-2">
                            <Form className="w-50">
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Control type="text" placeholder="Price"/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Control type="text" placeholder="Amount"/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Control type="text" placeholder="Total"/>
                                </Form.Group>
                                <button className="btn btn-danger w-100">Sell</button>
                            </Form>
                            <Form className="w-50">
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Control type="text" placeholder="Price"/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Control type="text" placeholder="Amount"/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Control type="text" placeholder="Total"/>
                                </Form.Group>
                                <button className="btn btn-success w-100">Buy</button>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TradeBasicPage
