// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-content {
    flex: 1 1;
    display: flex;
    flex-direction: column;
}

/* Navbar */
.navbar-container {
    position: sticky;
    top: 0;
    z-index: 10;
}

/* Page content area */
.auth-page-content {
    flex: 1 1;
    padding: 3rem 0 0 0;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/components/layout.css"],"names":[],"mappings":"AAAA;IACI,SAAO;IACP,aAAa;IACb,sBAAsB;AAC1B;;AAEA,WAAW;AACX;IACI,gBAAgB;IAChB,MAAM;IACN,WAAW;AACf;;AAEA,sBAAsB;AACtB;IACI,SAAO;IACP,mBAAmB;AACvB","sourcesContent":[".main-content {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n}\n\n/* Navbar */\n.navbar-container {\n    position: sticky;\n    top: 0;\n    z-index: 10;\n}\n\n/* Page content area */\n.auth-page-content {\n    flex: 1;\n    padding: 3rem 0 0 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
