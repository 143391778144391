// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.terms {
    padding-top: 25px;
    text-align: justify;
}

.terms__date {
    margin-top: 5px;
    font-size: 12px;
    color: var(--gray-600);
}

.terms__subtitle {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    text-align: left;
    font-weight: 400;
}

.terms__subtitle::after {
    content: '';
    display: inline-block;
    width: 80%;
    height: 1px;
    background-color: var(--gray-300);
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/pages/terms-of-services.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,eAAe;IACf,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,qBAAqB;IACrB,8BAA8B;IAC9B,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,qBAAqB;IACrB,UAAU;IACV,WAAW;IACX,iCAAiC;AACrC","sourcesContent":[".terms {\n    padding-top: 25px;\n    text-align: justify;\n}\n\n.terms__date {\n    margin-top: 5px;\n    font-size: 12px;\n    color: var(--gray-600);\n}\n\n.terms__subtitle {\n    display: flex;\n    align-items: baseline;\n    justify-content: space-between;\n    text-align: left;\n    font-weight: 400;\n}\n\n.terms__subtitle::after {\n    content: '';\n    display: inline-block;\n    width: 80%;\n    height: 1px;\n    background-color: var(--gray-300);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
