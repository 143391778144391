import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "assets/images/logo.svg";
import { appConstants } from "constants/app.constants";

const WelcomePageNavbar = () => {
    const [isNavVisible, setIsNavVisible] = useState(true);
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/login' || location.pathname === '/signup') {
            setIsNavVisible(false);
        } else {
            setIsNavVisible(true);
        }
    }, [location]);

    const joinNowButtonClass = location.pathname === '/' ? 'login-button btn btn-primary login-button-v2' : 'login-button btn btn-primary';

    return (
        <nav className='d-flex w-100 justify-content-between'>
                <a className="logo" href="/">
                    <img width={24} height={24} src={logo} alt="Logo"/>
                    {appConstants.NAME.UPPERCASE}
                </a>
            {isNavVisible &&
                <Link to='/login' className={joinNowButtonClass}>Join now</Link>
            }
        </nav>
    )
}

export default WelcomePageNavbar;
