import React from "react";
import heroImg from 'assets/images/hero-img.png';
import profileImg from 'assets/svg/profile.svg';
import verifyImg from 'assets/svg/verify.svg';
import walletImg from 'assets/svg/wallet.svg';
import tradeImg from 'assets/svg/trade.svg';
import { Link } from "react-router-dom";
import moment from "moment";
import { appConstants } from "constants/app.constants";

const WelcomePageV2 = () => {
    return (
        <div className='welcome-page-container'>
            <section className='hero-v2 d-flex align-items-center flex-column position-relative'>
                <div className='hero-v2__text-container position-relative'>
                    <div className='hero-v2__paragraph-container'>
                        <h1 className="hero-v2__title mb-3">
                              <span className="line line-1">
                                Your <span className="accent-color">All-in-One Crypto</span>
                              </span>
                            <span className="line line-2"> Trading Platform.</span>
                        </h1>
                    </div>
                    <p className='hero-v2__paragraph mb-4'>Track your balances, analyze performance,
                        and <br/> trade effortlessly with advanced
                        tools.</p>
                    <Link to='/login'
                          className="hero-v2__button floating button btn btn-primary d-flex align-items-center">Get Started
                        for Free
                    </Link>
                </div>
                <img className='hero-image-v2 position-absolute' src={heroImg} alt=""/>
            </section>
            <section className='get-started-section-v2 position-relative'>
                <div className='get-started-v2 d-flex text-center align-items-center flex-column'>
                    <div className='get-started-v2__text-container'>
                        <h2 className='get-started-v2__title'>Get Started in Just a <span
                            className="accent-color">Few Steps</span></h2>
                    </div>
                    <ul className='get-started-v2__list'>
                        <li className='get-started-v2__list-item'>
                            <img className='get-started-v2__list-item-icon' src={profileImg} alt="Profile"/>
                            <h3 className='get-started-v2__list-item-title'>Create an Account</h3>
                            <p className='get-started-v2__list-item-text'>Sign up in just a few minutes</p>
                        </li>
                        <li className='get-started-v2__list-item'>
                            <img className='get-started-v2__list-item-icon' src={verifyImg} alt="Verification"/>
                            <h3 className='get-started-v2__list-item-title'>Verify Your Account</h3>
                            <p className='get-started-v2__list-item-text'>Secure your account with our simple
                                verification process</p>
                        </li>
                        <li className='get-started-v2__list-item'>
                            <img className='get-started-v2__list-item-icon' src={walletImg} alt="Wallet"/>
                            <h3 className='get-started-v2__list-item-title'>Fund Your Wallet</h3>
                            <p className='get-started-v2__list-item-text'>Add funds easily through ur secure wallet</p>
                        </li>
                        <li className='get-started-v2__list-item'>
                            <img className='get-started-v2__list-item-icon' src={tradeImg} alt="Chart"/>
                            <h3 className='get-started-v2__list-item-title'>Start Trading</h3>
                            <p className='get-started-v2__list-item-text'>Buy, sell and manage your trades
                                instantly</p>
                        </li>
                    </ul>
                    <Link to='/login' className='button get-started-v2__button floating btn btn-primary mt-4'>Start
                        Trading</Link>
                </div>
                <div className='footer-container'>
                    <div className='footer d-flex justify-content-between p-2'>
                        <span>Copyright © {moment().year()} {appConstants.NAME.CAMELCASE}</span>
                        <div>
                            <span>All Rights Reserved | </span>
                            <a href='/terms-of-services'>Terms of Services | </a>
                            <a href='/privacy-policy'>Privacy Policy</a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default WelcomePageV2;
