import axiosClient from 'axiosClient/axiosClient';
import { fakeServerAxiosClient } from 'axiosClient/axiosClient';
import { appConstants } from "constants/app.constants";

// ================================================================================================================
// Authentication
// ================================================================================================================
export const signUp = async (payload: any) => {
    try {
        const response = await axiosClient.post('/jbst/security/registration/register0', payload);
        return { success: true, data: response.data };
    } catch (error: any) {
        return { error: error.response.data.attributes.shortMessage };
    }
};

export const login = async (payload: any) => {
    try {
        const response = await axiosClient.post('/jbst/security/authentication/login', payload);
        return { success: true, data: response.data };
    } catch (error: any) {
        return { error: error.response.data.attributes.shortMessage };
    }
};

export const logout = async () => {
    try {
        const response = await axiosClient.post('/jbst/security/authentication/logout');
        return { success: true, data: response.data };
    } catch (error: any) {
        return { error: error.response.data.attributes.shortMessage };
    }
};

// ================================================================================================================
// Session
// ================================================================================================================
export const getCurrentSession = async () => {
    const response = await axiosClient.get('/jbst/security/sessions/current');
    return response.data;
}

// ================================================================================================================
// CurrencyRates
// ================================================================================================================
export const getCurrencyRates = async (reload: boolean = false) => {
    try {
        const response = await axiosClient.get(`/v1/currency-rates/main-table?reload=${reload}`);
        return response.data
    }
    catch (error) {
        throw error;
    }
}

// ================================================================================================================
// Portfolio
// ================================================================================================================
export const getPortfolioInstant = async (payload: any) => {
    try {
        const response = await axiosClient.post(`/v1/accounts/${appConstants.ACCOUNT.HARDCODED}/portfolio/instant`, payload);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getPortfolioPerformance = async (payload: any) => {
    try {
        const response = await axiosClient.post(`/v1/accounts/${appConstants.ACCOUNT.HARDCODED}/portfolio/performance`, payload);
        return response.data;
    } catch (error) {
        throw error;
    }
}

// ================================================================================================================
// fake-server: Configs
// ================================================================================================================
export const getConfigsData = async () => {
    try {
        const response = await fakeServerAxiosClient.get('/configs');
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getCreationConfigsData = async () => {
    try {
        const response = await fakeServerAxiosClient.get('/configs/creation-keys');
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const addAPIKeys = async (apiKeys: {}) => {
    try {
        const response = await fakeServerAxiosClient.post('/configs', apiKeys);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const deleteConfigsData = async (exchangeName: string) => {
    try {
        const response = await fakeServerAxiosClient.delete(`/configs/${exchangeName}`);
        return response.data;
    } catch (error) {
        throw error;
    }
}
