export const timezoneConstants = [{
    value: 'Etc/GMT+12',
    label: '(-12:00) Etc/GMT+12'
}, {
    value: 'Pacific/Pago_Pago',
    label: '(-11:00) Pacific/Pago_Pago'
}, {
    value: 'Pacific/Samoa',
    label: '(-11:00) Pacific/Samoa'
}, {
    value: 'Pacific/Niue',
    label: '(-11:00) Pacific/Niue'
}, {
    value: 'US/Samoa',
    label: '(-11:00) US/Samoa'
}, {
    value: 'Etc/GMT+11',
    label: '(-11:00) Etc/GMT+11'
}, {
    value: 'Pacific/Mvalueway',
    label: '(-11:00) Pacific/Mvalueway'
}, {
    value: 'Pacific/Honolulu',
    label: '(-10:00) Pacific/Honolulu'
}, {
    value: 'Pacific/Rarotonga',
    label: '(-10:00) Pacific/Rarotonga'
}, {
    value: 'Pacific/Tahiti',
    label: '(-10:00) Pacific/Tahiti'
}, {
    value: 'Pacific/Johnston',
    label: '(-10:00) Pacific/Johnston'
}, {
    value: 'US/Hawaii',
    label: '(-10:00) US/Hawaii'
}, {
    value: 'SystemV/HST10',
    label: '(-10:00) SystemV/HST10'
}, {
    value: 'Etc/GMT+10',
    label: '(-10:00) Etc/GMT+10'
}, {
    value: 'Pacific/Marquesas',
    label: '(-09:30) Pacific/Marquesas'
}, {
    value: 'Etc/GMT+9',
    label: '(-09:00) Etc/GMT+9'
}, {
    value: 'Pacific/Gambier',
    label: '(-09:00) Pacific/Gambier'
}, {
    value: 'America/Atka',
    label: '(-09:00) America/Atka'
}, {
    value: 'SystemV/YST9',
    label: '(-09:00) SystemV/YST9'
}, {
    value: 'America/Adak',
    label: '(-09:00) America/Adak'
}, {
    value: 'US/Aleutian',
    label: '(-09:00) US/Aleutian'
}, {
    value: 'SystemV/YST9YDT',
    label: '(-09:00) SystemV/YST9YDT'
}, {
    value: 'Etc/GMT+8',
    label: '(-08:00) Etc/GMT+8'
}, {
    value: 'US/Alaska',
    label: '(-08:00) US/Alaska'
}, {
    value: 'America/Juneau',
    label: '(-08:00) America/Juneau'
}, {
    value: 'America/Metlakatla',
    label: '(-08:00) America/Metlakatla'
}, {
    value: 'America/Yakutat',
    label: '(-08:00) America/Yakutat'
}, {
    value: 'Pacific/Pitcairn',
    label: '(-08:00) Pacific/Pitcairn'
}, {
    value: 'America/Sitka',
    label: '(-08:00) America/Sitka'
}, {
    value: 'America/Anchorage',
    label: '(-08:00) America/Anchorage'
}, {
    value: 'SystemV/PST8',
    label: '(-08:00) SystemV/PST8'
}, {
    value: 'America/Nome',
    label: '(-08:00) America/Nome'
}, {
    value: 'SystemV/PST8PDT',
    label: '(-08:00) SystemV/PST8PDT'
}, {
    value: 'Canada/Yukon',
    label: '(-07:00) Canada/Yukon'
}, {
    value: 'Etc/GMT+7',
    label: '(-07:00) Etc/GMT+7'
}, {
    value: 'US/Arizona',
    label: '(-07:00) US/Arizona'
}, {
    value: 'Mexico/BajaSur',
    label: '(-07:00) Mexico/BajaSur'
}, {
    value: 'America/Dawson_Creek',
    label: '(-07:00) America/Dawson_Creek'
}, {
    value: 'Canada/Pacific',
    label: '(-07:00) Canada/Pacific'
}, {
    value: 'PST8PDT',
    label: '(-07:00) PST8PDT'
}, {
    value: 'America/Mazatlan',
    label: '(-07:00) America/Mazatlan'
}, {
    value: 'SystemV/MST7',
    label: '(-07:00) SystemV/MST7'
}, {
    value: 'America/Dawson',
    label: '(-07:00) America/Dawson'
}, {
    value: 'Mexico/BajaNorte',
    label: '(-07:00) Mexico/BajaNorte'
}, {
    value: 'America/Tijuana',
    label: '(-07:00) America/Tijuana'
}, {
    value: 'America/Creston',
    label: '(-07:00) America/Creston'
}, {
    value: 'America/Hermosillo',
    label: '(-07:00) America/Hermosillo'
}, {
    value: 'America/Santa_Isabel',
    label: '(-07:00) America/Santa_Isabel'
}, {
    value: 'America/Vancouver',
    label: '(-07:00) America/Vancouver'
}, {
    value: 'America/Ensenada',
    label: '(-07:00) America/Ensenada'
}, {
    value: 'America/Phoenix',
    label: '(-07:00) America/Phoenix'
}, {
    value: 'America/Whitehorse',
    label: '(-07:00) America/Whitehorse'
}, {
    value: 'SystemV/MST7MDT',
    label: '(-07:00) SystemV/MST7MDT'
}, {
    value: 'America/Fort_Nelson',
    label: '(-07:00) America/Fort_Nelson'
}, {
    value: 'America/Los_Angeles',
    label: '(-07:00) America/Los_Angeles'
}, {
    value: 'US/Pacific',
    label: '(-07:00) US/Pacific'
}, {
    value: 'America/El_Salvador',
    label: '(-06:00) America/El_Salvador'
}, {
    value: 'America/Guatemala',
    label: '(-06:00) America/Guatemala'
}, {
    value: 'America/Belize',
    label: '(-06:00) America/Belize'
}, {
    value: 'America/Managua',
    label: '(-06:00) America/Managua'
}, {
    value: 'America/Tegucigalpa',
    label: '(-06:00) America/Tegucigalpa'
}, {
    value: 'Etc/GMT+6',
    label: '(-06:00) Etc/GMT+6'
}, {
    value: 'Pacific/Easter',
    label: '(-06:00) Pacific/Easter'
}, {
    value: 'America/Regina',
    label: '(-06:00) America/Regina'
}, {
    value: 'America/Denver',
    label: '(-06:00) America/Denver'
}, {
    value: 'Mexico/General',
    label: '(-06:00) Mexico/General'
}, {
    value: 'Pacific/Galapagos',
    label: '(-06:00) Pacific/Galapagos'
}, {
    value: 'America/Yellowknife',
    label: '(-06:00) America/Yellowknife'
}, {
    value: 'America/Swift_Current',
    label: '(-06:00) America/Swift_Current'
}, {
    value: 'America/Inuvik',
    label: '(-06:00) America/Inuvik'
}, {
    value: 'America/Ciudad_Juarez',
    label: '(-06:00) America/Ciudad_Juarez'
}, {
    value: 'America/Boise',
    label: '(-06:00) America/Boise'
}, {
    value: 'America/Costa_Rica',
    label: '(-06:00) America/Costa_Rica'
}, {
    value: 'MST7MDT',
    label: '(-06:00) MST7MDT'
}, {
    value: 'America/Monterrey',
    label: '(-06:00) America/Monterrey'
}, {
    value: 'SystemV/CST6',
    label: '(-06:00) SystemV/CST6'
}, {
    value: 'America/Chihuahua',
    label: '(-06:00) America/Chihuahua'
}, {
    value: 'Chile/EasterIsland',
    label: '(-06:00) Chile/EasterIsland'
}, {
    value: 'US/Mountain',
    label: '(-06:00) US/Mountain'
}, {
    value: 'America/Mexico_City',
    label: '(-06:00) America/Mexico_City'
}, {
    value: 'America/Edmonton',
    label: '(-06:00) America/Edmonton'
}, {
    value: 'America/Bahia_Banderas',
    label: '(-06:00) America/Bahia_Banderas'
}, {
    value: 'Canada/Mountain',
    label: '(-06:00) Canada/Mountain'
}, {
    value: 'America/Cambrvaluege_Bay',
    label: '(-06:00) America/Cambrvaluege_Bay'
}, {
    value: 'SystemV/CST6CDT',
    label: '(-06:00) SystemV/CST6CDT'
}, {
    value: 'Navajo',
    label: '(-06:00) Navajo'
}, {
    value: 'America/Mervaluea',
    label: '(-06:00) America/Mervaluea'
}, {
    value: 'Canada/Saskatchewan',
    label: '(-06:00) Canada/Saskatchewan'
}, {
    value: 'America/Shiprock',
    label: '(-06:00) America/Shiprock'
}, {
    value: 'America/Panama',
    label: '(-05:00) America/Panama'
}, {
    value: 'America/Chicago',
    label: '(-05:00) America/Chicago'
}, {
    value: 'America/Eirunepe',
    label: '(-05:00) America/Eirunepe'
}, {
    value: 'Etc/GMT+5',
    label: '(-05:00) Etc/GMT+5'
}, {
    value: 'America/Porto_Acre',
    label: '(-05:00) America/Porto_Acre'
}, {
    value: 'America/Guayaquil',
    label: '(-05:00) America/Guayaquil'
}, {
    value: 'America/Rankin_Inlet',
    label: '(-05:00) America/Rankin_Inlet'
}, {
    value: 'US/Central',
    label: '(-05:00) US/Central'
}, {
    value: 'America/Rainy_River',
    label: '(-05:00) America/Rainy_River'
}, {
    value: 'America/Indiana/Knox',
    label: '(-05:00) America/Indiana/Knox'
}, {
    value: 'America/North_Dakota/Beulah',
    label: '(-05:00) America/North_Dakota/Beulah'
}, {
    value: 'America/Jamaica',
    label: '(-05:00) America/Jamaica'
}, {
    value: 'America/Atikokan',
    label: '(-05:00) America/Atikokan'
}, {
    value: 'America/Coral_Harbour',
    label: '(-05:00) America/Coral_Harbour'
}, {
    value: 'America/North_Dakota/Center',
    label: '(-05:00) America/North_Dakota/Center'
}, {
    value: 'America/Cayman',
    label: '(-05:00) America/Cayman'
}, {
    value: 'America/Indiana/Tell_City',
    label: '(-05:00) America/Indiana/Tell_City'
}, {
    value: 'America/Ojinaga',
    label: '(-05:00) America/Ojinaga'
}, {
    value: 'America/Matamoros',
    label: '(-05:00) America/Matamoros'
}, {
    value: 'CST6CDT',
    label: '(-05:00) CST6CDT'
}, {
    value: 'America/Knox_IN',
    label: '(-05:00) America/Knox_IN'
}, {
    value: 'America/Bogota',
    label: '(-05:00) America/Bogota'
}, {
    value: 'America/Menominee',
    label: '(-05:00) America/Menominee'
}, {
    value: 'America/Resolute',
    label: '(-05:00) America/Resolute'
}, {
    value: 'SystemV/EST5',
    label: '(-05:00) SystemV/EST5'
}, {
    value: 'Canada/Central',
    label: '(-05:00) Canada/Central'
}, {
    value: 'Brazil/Acre',
    label: '(-05:00) Brazil/Acre'
}, {
    value: 'America/Cancun',
    label: '(-05:00) America/Cancun'
}, {
    value: 'America/Lima',
    label: '(-05:00) America/Lima'
}, {
    value: 'US/Indiana-Starke',
    label: '(-05:00) US/Indiana-Starke'
}, {
    value: 'America/Rio_Branco',
    label: '(-05:00) America/Rio_Branco'
}, {
    value: 'Jamaica',
    label: '(-05:00) Jamaica'
}, {
    value: 'America/North_Dakota/New_Salem',
    label: '(-05:00) America/North_Dakota/New_Salem'
}, {
    value: 'America/Winnipeg',
    label: '(-05:00) America/Winnipeg'
}, {
    value: 'SystemV/EST5EDT',
    label: '(-05:00) SystemV/EST5EDT'
}, {
    value: 'America/Cuiaba',
    label: '(-04:00) America/Cuiaba'
}, {
    value: 'America/Marigot',
    label: '(-04:00) America/Marigot'
}, {
    value: 'America/Indiana/Petersburg',
    label: '(-04:00) America/Indiana/Petersburg'
}, {
    value: 'Chile/Continental',
    label: '(-04:00) Chile/Continental'
}, {
    value: 'America/Grand_Turk',
    label: '(-04:00) America/Grand_Turk'
}, {
    value: 'Cuba',
    label: '(-04:00) Cuba'
}, {
    value: 'Etc/GMT+4',
    label: '(-04:00) Etc/GMT+4'
}, {
    value: 'America/Manaus',
    label: '(-04:00) America/Manaus'
}, {
    value: 'America/Fort_Wayne',
    label: '(-04:00) America/Fort_Wayne'
}, {
    value: 'America/St_Thomas',
    label: '(-04:00) America/St_Thomas'
}, {
    value: 'America/Anguilla',
    label: '(-04:00) America/Anguilla'
}, {
    value: 'America/Havana',
    label: '(-04:00) America/Havana'
}, {
    value: 'US/Michigan',
    label: '(-04:00) US/Michigan'
}, {
    value: 'America/Barbados',
    label: '(-04:00) America/Barbados'
}, {
    value: 'America/Louisville',
    label: '(-04:00) America/Louisville'
}, {
    value: 'America/Curacao',
    label: '(-04:00) America/Curacao'
}, {
    value: 'America/Guyana',
    label: '(-04:00) America/Guyana'
}, {
    value: 'America/Martinique',
    label: '(-04:00) America/Martinique'
}, {
    value: 'America/Puerto_Rico',
    label: '(-04:00) America/Puerto_Rico'
}, {
    value: 'America/Port_of_Spain',
    label: '(-04:00) America/Port_of_Spain'
}, {
    value: 'SystemV/AST4',
    label: '(-04:00) SystemV/AST4'
}, {
    value: 'America/Indiana/Vevay',
    label: '(-04:00) America/Indiana/Vevay'
}, {
    value: 'America/Indiana/Vincennes',
    label: '(-04:00) America/Indiana/Vincennes'
}, {
    value: 'America/Kralendijk',
    label: '(-04:00) America/Kralendijk'
}, {
    value: 'America/Antigua',
    label: '(-04:00) America/Antigua'
}, {
    value: 'America/Indianapolis',
    label: '(-04:00) America/Indianapolis'
}, {
    value: 'America/Iqaluit',
    label: '(-04:00) America/Iqaluit'
}, {
    value: 'America/St_Vincent',
    label: '(-04:00) America/St_Vincent'
}, {
    value: 'America/Kentucky/Louisville',
    label: '(-04:00) America/Kentucky/Louisville'
}, {
    value: 'America/Dominica',
    label: '(-04:00) America/Dominica'
}, {
    value: 'America/Asuncion',
    label: '(-04:00) America/Asuncion'
}, {
    value: 'EST5EDT',
    label: '(-04:00) EST5EDT'
}, {
    value: 'America/Nassau',
    label: '(-04:00) America/Nassau'
}, {
    value: 'America/Kentucky/Monticello',
    label: '(-04:00) America/Kentucky/Monticello'
}, {
    value: 'Brazil/West',
    label: '(-04:00) Brazil/West'
}, {
    value: 'America/Aruba',
    label: '(-04:00) America/Aruba'
}, {
    value: 'America/Indiana/Indianapolis',
    label: '(-04:00) America/Indiana/Indianapolis'
}, {
    value: 'America/Santiago',
    label: '(-04:00) America/Santiago'
}, {
    value: 'America/La_Paz',
    label: '(-04:00) America/La_Paz'
}, {
    value: 'America/Thunder_Bay',
    label: '(-04:00) America/Thunder_Bay'
}, {
    value: 'America/Indiana/Marengo',
    label: '(-04:00) America/Indiana/Marengo'
}, {
    value: 'America/Blanc-Sablon',
    label: '(-04:00) America/Blanc-Sablon'
}, {
    value: 'America/Santo_Domingo',
    label: '(-04:00) America/Santo_Domingo'
}, {
    value: 'US/Eastern',
    label: '(-04:00) US/Eastern'
}, {
    value: 'Canada/Eastern',
    label: '(-04:00) Canada/Eastern'
}, {
    value: 'America/Port-au-Prince',
    label: '(-04:00) America/Port-au-Prince'
}, {
    value: 'America/St_Barthelemy',
    label: '(-04:00) America/St_Barthelemy'
}, {
    value: 'America/Nipigon',
    label: '(-04:00) America/Nipigon'
}, {
    value: 'US/East-Indiana',
    label: '(-04:00) US/East-Indiana'
}, {
    value: 'America/St_Lucia',
    label: '(-04:00) America/St_Lucia'
}, {
    value: 'America/Montserrat',
    label: '(-04:00) America/Montserrat'
}, {
    value: 'America/Lower_Princes',
    label: '(-04:00) America/Lower_Princes'
}, {
    value: 'America/Detroit',
    label: '(-04:00) America/Detroit'
}, {
    value: 'America/Tortola',
    label: '(-04:00) America/Tortola'
}, {
    value: 'America/Porto_Velho',
    label: '(-04:00) America/Porto_Velho'
}, {
    value: 'America/Campo_Grande',
    label: '(-04:00) America/Campo_Grande'
}, {
    value: 'America/Virgin',
    label: '(-04:00) America/Virgin'
}, {
    value: 'America/Pangnirtung',
    label: '(-04:00) America/Pangnirtung'
}, {
    value: 'America/Montreal',
    label: '(-04:00) America/Montreal'
}, {
    value: 'America/Indiana/Winamac',
    label: '(-04:00) America/Indiana/Winamac'
}, {
    value: 'America/Boa_Vista',
    label: '(-04:00) America/Boa_Vista'
}, {
    value: 'America/Grenada',
    label: '(-04:00) America/Grenada'
}, {
    value: 'America/New_York',
    label: '(-04:00) America/New_York'
}, {
    value: 'America/St_Kitts',
    label: '(-04:00) America/St_Kitts'
}, {
    value: 'America/Caracas',
    label: '(-04:00) America/Caracas'
}, {
    value: 'America/Guadeloupe',
    label: '(-04:00) America/Guadeloupe'
}, {
    value: 'SystemV/AST4ADT',
    label: '(-04:00) SystemV/AST4ADT'
}, {
    value: 'America/Toronto',
    label: '(-04:00) America/Toronto'
}, {
    value: 'America/Argentina/Catamarca',
    label: '(-03:00) America/Argentina/Catamarca'
}, {
    value: 'Canada/Atlantic',
    label: '(-03:00) Canada/Atlantic'
}, {
    value: 'America/Argentina/Cordoba',
    label: '(-03:00) America/Argentina/Cordoba'
}, {
    value: 'America/Araguaina',
    label: '(-03:00) America/Araguaina'
}, {
    value: 'America/Argentina/Salta',
    label: '(-03:00) America/Argentina/Salta'
}, {
    value: 'Etc/GMT+3',
    label: '(-03:00) Etc/GMT+3'
}, {
    value: 'America/Montevvalueeo',
    label: '(-03:00) America/Montevvalueeo'
}, {
    value: 'Brazil/East',
    label: '(-03:00) Brazil/East'
}, {
    value: 'America/Argentina/Mendoza',
    label: '(-03:00) America/Argentina/Mendoza'
}, {
    value: 'America/Argentina/Rio_Gallegos',
    label: '(-03:00) America/Argentina/Rio_Gallegos'
}, {
    value: 'America/Catamarca',
    label: '(-03:00) America/Catamarca'
}, {
    value: 'America/Cordoba',
    label: '(-03:00) America/Cordoba'
}, {
    value: 'America/Sao_Paulo',
    label: '(-03:00) America/Sao_Paulo'
}, {
    value: 'America/Argentina/Jujuy',
    label: '(-03:00) America/Argentina/Jujuy'
}, {
    value: 'America/Cayenne',
    label: '(-03:00) America/Cayenne'
}, {
    value: 'America/Recife',
    label: '(-03:00) America/Recife'
}, {
    value: 'America/Buenos_Aires',
    label: '(-03:00) America/Buenos_Aires'
}, {
    value: 'America/Paramaribo',
    label: '(-03:00) America/Paramaribo'
}, {
    value: 'America/Moncton',
    label: '(-03:00) America/Moncton'
}, {
    value: 'America/Mendoza',
    label: '(-03:00) America/Mendoza'
}, {
    value: 'America/Santarem',
    label: '(-03:00) America/Santarem'
}, {
    value: 'Atlantic/Bermuda',
    label: '(-03:00) Atlantic/Bermuda'
}, {
    value: 'America/Maceio',
    label: '(-03:00) America/Maceio'
}, {
    value: 'Atlantic/Stanley',
    label: '(-03:00) Atlantic/Stanley'
}, {
    value: 'America/Halifax',
    label: '(-03:00) America/Halifax'
}, {
    value: 'Antarctica/Rothera',
    label: '(-03:00) Antarctica/Rothera'
}, {
    value: 'America/Argentina/San_Luis',
    label: '(-03:00) America/Argentina/San_Luis'
}, {
    value: 'America/Argentina/Ushuaia',
    label: '(-03:00) America/Argentina/Ushuaia'
}, {
    value: 'Antarctica/Palmer',
    label: '(-03:00) Antarctica/Palmer'
}, {
    value: 'America/Punta_Arenas',
    label: '(-03:00) America/Punta_Arenas'
}, {
    value: 'America/Glace_Bay',
    label: '(-03:00) America/Glace_Bay'
}, {
    value: 'America/Fortaleza',
    label: '(-03:00) America/Fortaleza'
}, {
    value: 'America/Thule',
    label: '(-03:00) America/Thule'
}, {
    value: 'America/Argentina/La_Rioja',
    label: '(-03:00) America/Argentina/La_Rioja'
}, {
    value: 'America/Belem',
    label: '(-03:00) America/Belem'
}, {
    value: 'America/Jujuy',
    label: '(-03:00) America/Jujuy'
}, {
    value: 'America/Bahia',
    label: '(-03:00) America/Bahia'
}, {
    value: 'America/Goose_Bay',
    label: '(-03:00) America/Goose_Bay'
}, {
    value: 'America/Argentina/San_Juan',
    label: '(-03:00) America/Argentina/San_Juan'
}, {
    value: 'America/Argentina/ComodRivadavia',
    label: '(-03:00) America/Argentina/ComodRivadavia'
}, {
    value: 'America/Argentina/Tucuman',
    label: '(-03:00) America/Argentina/Tucuman'
}, {
    value: 'America/Rosario',
    label: '(-03:00) America/Rosario'
}, {
    value: 'America/Argentina/Buenos_Aires',
    label: '(-03:00) America/Argentina/Buenos_Aires'
}, {
    value: 'America/St_Johns',
    label: '(-02:30) America/St_Johns'
}, {
    value: 'Canada/Newfoundland',
    label: '(-02:30) Canada/Newfoundland'
}, {
    value: 'America/Miquelon',
    label: '(-02:00) America/Miquelon'
}, {
    value: 'Etc/GMT+2',
    label: '(-02:00) Etc/GMT+2'
}, {
    value: 'America/Godthab',
    label: '(-02:00) America/Godthab'
}, {
    value: 'America/Noronha',
    label: '(-02:00) America/Noronha'
}, {
    value: 'Brazil/DeNoronha',
    label: '(-02:00) Brazil/DeNoronha'
}, {
    value: 'Atlantic/South_Georgia',
    label: '(-02:00) Atlantic/South_Georgia'
}, {
    value: 'America/Nuuk',
    label: '(-02:00) America/Nuuk'
}, {
    value: 'Etc/GMT+1',
    label: '(-01:00) Etc/GMT+1'
}, {
    value: 'Atlantic/Cape_Verde',
    label: '(-01:00) Atlantic/Cape_Verde'
}, {
    value: 'GMT',
    label: '(Z) GMT'
}, {
    value: 'Etc/GMT-0',
    label: '(Z) Etc/GMT-0'
}, {
    value: 'Atlantic/St_Helena',
    label: '(Z) Atlantic/St_Helena'
}, {
    value: 'Etc/GMT+0',
    label: '(Z) Etc/GMT+0'
}, {
    value: 'Africa/Banjul',
    label: '(Z) Africa/Banjul'
}, {
    value: 'Etc/GMT',
    label: '(Z) Etc/GMT'
}, {
    value: 'Africa/Freetown',
    label: '(Z) Africa/Freetown'
}, {
    value: 'Africa/Bamako',
    label: '(Z) Africa/Bamako'
}, {
    value: 'Africa/Conakry',
    label: '(Z) Africa/Conakry'
}, {
    value: 'Universal',
    label: '(Z) Universal'
}, {
    value: 'Africa/Sao_Tome',
    label: '(Z) Africa/Sao_Tome'
}, {
    value: 'Africa/Nouakchott',
    label: '(Z) Africa/Nouakchott'
}, {
    value: 'UTC',
    label: '(Z) UTC'
}, {
    value: 'Etc/Universal',
    label: '(Z) Etc/Universal'
}, {
    value: 'Atlantic/Azores',
    label: '(Z) Atlantic/Azores'
}, {
    value: 'Africa/Abvaluejan',
    label: '(Z) Africa/Abvaluejan'
}, {
    value: 'Africa/Accra',
    label: '(Z) Africa/Accra'
}, {
    value: 'Etc/UCT',
    label: '(Z) Etc/UCT'
}, {
    value: 'GMT0',
    label: '(Z) GMT0'
}, {
    value: 'Zulu',
    label: '(Z) Zulu'
}, {
    value: 'Africa/Ouagadougou',
    label: '(Z) Africa/Ouagadougou'
}, {
    value: 'Atlantic/Reykjavik',
    label: '(Z) Atlantic/Reykjavik'
}, {
    value: 'Etc/Zulu',
    label: '(Z) Etc/Zulu'
}, {
    value: 'Iceland',
    label: '(Z) Iceland'
}, {
    value: 'Africa/Lome',
    label: '(Z) Africa/Lome'
}, {
    value: 'Greenwich',
    label: '(Z) Greenwich'
}, {
    value: 'Etc/GMT0',
    label: '(Z) Etc/GMT0'
}, {
    value: 'America/Danmarkshavn',
    label: '(Z) America/Danmarkshavn'
}, {
    value: 'Africa/Dakar',
    label: '(Z) Africa/Dakar'
}, {
    value: 'America/Scoresbysund',
    label: '(Z) America/Scoresbysund'
}, {
    value: 'Africa/Bissau',
    label: '(Z) Africa/Bissau'
}, {
    value: 'Etc/Greenwich',
    label: '(Z) Etc/Greenwich'
}, {
    value: 'Africa/Timbuktu',
    label: '(Z) Africa/Timbuktu'
}, {
    value: 'UCT',
    label: '(Z) UCT'
}, {
    value: 'Africa/Monrovia',
    label: '(Z) Africa/Monrovia'
}, {
    value: 'Etc/UTC',
    label: '(Z) Etc/UTC'
}, {
    value: 'Europe/London',
    label: '(+01:00) Europe/London'
}, {
    value: 'Etc/GMT-1',
    label: '(+01:00) Etc/GMT-1'
}, {
    value: 'Europe/Jersey',
    label: '(+01:00) Europe/Jersey'
}, {
    value: 'Europe/Guernsey',
    label: '(+01:00) Europe/Guernsey'
}, {
    value: 'Europe/Isle_of_Man',
    label: '(+01:00) Europe/Isle_of_Man'
}, {
    value: 'Africa/Tunis',
    label: '(+01:00) Africa/Tunis'
}, {
    value: 'Africa/Malabo',
    label: '(+01:00) Africa/Malabo'
}, {
    value: 'GB-Eire',
    label: '(+01:00) GB-Eire'
}, {
    value: 'Africa/Lagos',
    label: '(+01:00) Africa/Lagos'
}, {
    value: 'Africa/Algiers',
    label: '(+01:00) Africa/Algiers'
}, {
    value: 'GB',
    label: '(+01:00) GB'
}, {
    value: 'Portugal',
    label: '(+01:00) Portugal'
}, {
    value: 'Africa/Ndjamena',
    label: '(+01:00) Africa/Ndjamena'
}, {
    value: 'Atlantic/Faeroe',
    label: '(+01:00) Atlantic/Faeroe'
}, {
    value: 'Eire',
    label: '(+01:00) Eire'
}, {
    value: 'Atlantic/Faroe',
    label: '(+01:00) Atlantic/Faroe'
}, {
    value: 'Europe/Dublin',
    label: '(+01:00) Europe/Dublin'
}, {
    value: 'Africa/Libreville',
    label: '(+01:00) Africa/Libreville'
}, {
    value: 'Africa/El_Aaiun',
    label: '(+01:00) Africa/El_Aaiun'
}, {
    value: 'Africa/Douala',
    label: '(+01:00) Africa/Douala'
}, {
    value: 'Africa/Brazzaville',
    label: '(+01:00) Africa/Brazzaville'
}, {
    value: 'Africa/Porto-Novo',
    label: '(+01:00) Africa/Porto-Novo'
}, {
    value: 'Atlantic/Madeira',
    label: '(+01:00) Atlantic/Madeira'
}, {
    value: 'Europe/Lisbon',
    label: '(+01:00) Europe/Lisbon'
}, {
    value: 'Atlantic/Canary',
    label: '(+01:00) Atlantic/Canary'
}, {
    value: 'Africa/Casablanca',
    label: '(+01:00) Africa/Casablanca'
}, {
    value: 'Europe/Belfast',
    label: '(+01:00) Europe/Belfast'
}, {
    value: 'Africa/Luanda',
    label: '(+01:00) Africa/Luanda'
}, {
    value: 'Africa/Kinshasa',
    label: '(+01:00) Africa/Kinshasa'
}, {
    value: 'Africa/Bangui',
    label: '(+01:00) Africa/Bangui'
}, {
    value: 'WET',
    label: '(+01:00) WET'
}, {
    value: 'Africa/Niamey',
    label: '(+01:00) Africa/Niamey'
}, {
    value: 'Africa/Cairo',
    label: '(+02:00) Africa/Cairo'
}, {
    value: 'Africa/Mbabane',
    label: '(+02:00) Africa/Mbabane'
}, {
    value: 'Europe/Brussels',
    label: '(+02:00) Europe/Brussels'
}, {
    value: 'Europe/Warsaw',
    label: '(+02:00) Europe/Warsaw'
}, {
    value: 'CET',
    label: '(+02:00) CET'
}, {
    value: 'Europe/Luxembourg',
    label: '(+02:00) Europe/Luxembourg'
}, {
    value: 'Etc/GMT-2',
    label: '(+02:00) Etc/GMT-2'
}, {
    value: 'Libya',
    label: '(+02:00) Libya'
}, {
    value: 'Africa/Kigali',
    label: '(+02:00) Africa/Kigali'
}, {
    value: 'Africa/Tripoli',
    label: '(+02:00) Africa/Tripoli'
}, {
    value: 'Europe/Kaliningrad',
    label: '(+02:00) Europe/Kaliningrad'
}, {
    value: 'Africa/Windhoek',
    label: '(+02:00) Africa/Windhoek'
}, {
    value: 'Europe/Malta',
    label: '(+02:00) Europe/Malta'
}, {
    value: 'Europe/Busingen',
    label: '(+02:00) Europe/Busingen'
}, {
    value: 'Europe/Skopje',
    label: '(+02:00) Europe/Skopje'
}, {
    value: 'Europe/Sarajevo',
    label: '(+02:00) Europe/Sarajevo'
}, {
    value: 'Europe/Rome',
    label: '(+02:00) Europe/Rome'
}, {
    value: 'Europe/Zurich',
    label: '(+02:00) Europe/Zurich'
}, {
    value: 'Europe/Gibraltar',
    label: '(+02:00) Europe/Gibraltar'
}, {
    value: 'Africa/Lubumbashi',
    label: '(+02:00) Africa/Lubumbashi'
}, {
    value: 'Europe/Vaduz',
    label: '(+02:00) Europe/Vaduz'
}, {
    value: 'Europe/Ljubljana',
    label: '(+02:00) Europe/Ljubljana'
}, {
    value: 'Europe/Berlin',
    label: '(+02:00) Europe/Berlin'
}, {
    value: 'Europe/Stockholm',
    label: '(+02:00) Europe/Stockholm'
}, {
    value: 'Europe/Budapest',
    label: '(+02:00) Europe/Budapest'
}, {
    value: 'Europe/Zagreb',
    label: '(+02:00) Europe/Zagreb'
}, {
    value: 'Europe/Paris',
    label: '(+02:00) Europe/Paris'
}, {
    value: 'Africa/Ceuta',
    label: '(+02:00) Africa/Ceuta'
}, {
    value: 'Europe/Prague',
    label: '(+02:00) Europe/Prague'
}, {
    value: 'Antarctica/Troll',
    label: '(+02:00) Antarctica/Troll'
}, {
    value: 'Africa/Gaborone',
    label: '(+02:00) Africa/Gaborone'
}, {
    value: 'Europe/Copenhagen',
    label: '(+02:00) Europe/Copenhagen'
}, {
    value: 'Europe/Vienna',
    label: '(+02:00) Europe/Vienna'
}, {
    value: 'Europe/Tirane',
    label: '(+02:00) Europe/Tirane'
}, {
    value: 'MET',
    label: '(+02:00) MET'
}, {
    value: 'Europe/Amsterdam',
    label: '(+02:00) Europe/Amsterdam'
}, {
    value: 'Africa/Maputo',
    label: '(+02:00) Africa/Maputo'
}, {
    value: 'Europe/San_Marino',
    label: '(+02:00) Europe/San_Marino'
}, {
    value: 'Poland',
    label: '(+02:00) Poland'
}, {
    value: 'Europe/Andorra',
    label: '(+02:00) Europe/Andorra'
}, {
    value: 'Europe/Oslo',
    label: '(+02:00) Europe/Oslo'
}, {
    value: 'Europe/Podgorica',
    label: '(+02:00) Europe/Podgorica'
}, {
    value: 'Africa/Bujumbura',
    label: '(+02:00) Africa/Bujumbura'
}, {
    value: 'Atlantic/Jan_Mayen',
    label: '(+02:00) Atlantic/Jan_Mayen'
}, {
    value: 'Africa/Maseru',
    label: '(+02:00) Africa/Maseru'
}, {
    value: 'Europe/Madrvalue',
    label: '(+02:00) Europe/Madrvalue'
}, {
    value: 'Africa/Blantyre',
    label: '(+02:00) Africa/Blantyre'
}, {
    value: 'Africa/Lusaka',
    label: '(+02:00) Africa/Lusaka'
}, {
    value: 'Africa/Harare',
    label: '(+02:00) Africa/Harare'
}, {
    value: 'Africa/Khartoum',
    label: '(+02:00) Africa/Khartoum'
}, {
    value: 'Africa/Johannesburg',
    label: '(+02:00) Africa/Johannesburg'
}, {
    value: 'Europe/Belgrade',
    label: '(+02:00) Europe/Belgrade'
}, {
    value: 'Africa/Juba',
    label: '(+02:00) Africa/Juba'
}, {
    value: 'Europe/Bratislava',
    label: '(+02:00) Europe/Bratislava'
}, {
    value: 'Arctic/Longyearbyen',
    label: '(+02:00) Arctic/Longyearbyen'
}, {
    value: 'Egypt',
    label: '(+02:00) Egypt'
}, {
    value: 'Europe/Vatican',
    label: '(+02:00) Europe/Vatican'
}, {
    value: 'Europe/Monaco',
    label: '(+02:00) Europe/Monaco'
}, {
    value: 'Asia/Aden',
    label: '(+03:00) Asia/Aden'
}, {
    value: 'Africa/Nairobi',
    label: '(+03:00) Africa/Nairobi'
}, {
    value: 'Europe/Istanbul',
    label: '(+03:00) Europe/Istanbul'
}, {
    value: 'Etc/GMT-3',
    label: '(+03:00) Etc/GMT-3'
}, {
    value: 'Europe/Zaporozhye',
    label: '(+03:00) Europe/Zaporozhye'
}, {
    value: 'Israel',
    label: '(+03:00) Israel'
}, {
    value: 'Indian/Comoro',
    label: '(+03:00) Indian/Comoro'
}, {
    value: 'Antarctica/Syowa',
    label: '(+03:00) Antarctica/Syowa'
}, {
    value: 'Africa/Mogadishu',
    label: '(+03:00) Africa/Mogadishu'
}, {
    value: 'Europe/Bucharest',
    label: '(+03:00) Europe/Bucharest'
}, {
    value: 'Africa/Asmera',
    label: '(+03:00) Africa/Asmera'
}, {
    value: 'Europe/Mariehamn',
    label: '(+03:00) Europe/Mariehamn'
}, {
    value: 'Asia/Istanbul',
    label: '(+03:00) Asia/Istanbul'
}, {
    value: 'Europe/Tiraspol',
    label: '(+03:00) Europe/Tiraspol'
}, {
    value: 'Europe/Moscow',
    label: '(+03:00) Europe/Moscow'
}, {
    value: 'Europe/Chisinau',
    label: '(+03:00) Europe/Chisinau'
}, {
    value: 'Europe/Helsinki',
    label: '(+03:00) Europe/Helsinki'
}, {
    value: 'Asia/Beirut',
    label: '(+03:00) Asia/Beirut'
}, {
    value: 'Asia/Tel_Aviv',
    label: '(+03:00) Asia/Tel_Aviv'
}, {
    value: 'Africa/Djibouti',
    label: '(+03:00) Africa/Djibouti'
}, {
    value: 'Europe/Simferopol',
    label: '(+03:00) Europe/Simferopol'
}, {
    value: 'Europe/Sofia',
    label: '(+03:00) Europe/Sofia'
}, {
    value: 'Asia/Gaza',
    label: '(+03:00) Asia/Gaza'
}, {
    value: 'Africa/Asmara',
    label: '(+03:00) Africa/Asmara'
}, {
    value: 'Europe/Riga',
    label: '(+03:00) Europe/Riga'
}, {
    value: 'Asia/Baghdad',
    label: '(+03:00) Asia/Baghdad'
}, {
    value: 'Asia/Damascus',
    label: '(+03:00) Asia/Damascus'
}, {
    value: 'Africa/Dar_es_Salaam',
    label: '(+03:00) Africa/Dar_es_Salaam'
}, {
    value: 'Africa/Addis_Ababa',
    label: '(+03:00) Africa/Addis_Ababa'
}, {
    value: 'Europe/Uzhgorod',
    label: '(+03:00) Europe/Uzhgorod'
}, {
    value: 'Asia/Jerusalem',
    label: '(+03:00) Asia/Jerusalem'
}, {
    value: 'Asia/Riyadh',
    label: '(+03:00) Asia/Riyadh'
}, {
    value: 'Asia/Kuwait',
    label: '(+03:00) Asia/Kuwait'
}, {
    value: 'Europe/Kirov',
    label: '(+03:00) Europe/Kirov'
}, {
    value: 'Africa/Kampala',
    label: '(+03:00) Africa/Kampala'
}, {
    value: 'Europe/Minsk',
    label: '(+03:00) Europe/Minsk'
}, {
    value: 'Asia/Qatar',
    label: '(+03:00) Asia/Qatar'
}, {
    value: 'Asia/Bahrain',
    label: '(+03:00) Asia/Bahrain'
}, {
    value: 'Europe/Vilnius',
    label: '(+03:00) Europe/Vilnius'
}, {
    value: 'Indian/Antananarivo',
    label: '(+03:00) Indian/Antananarivo'
}, {
    value: 'Indian/Mayotte',
    label: '(+03:00) Indian/Mayotte'
}, {
    value: 'Europe/Volgograd',
    label: '(+03:00) Europe/Volgograd'
}, {
    value: 'Europe/Tallinn',
    label: '(+03:00) Europe/Tallinn'
}, {
    value: 'Turkey',
    label: '(+03:00) Turkey'
}, {
    value: 'Europe/Kyiv',
    label: '(+03:00) Europe/Kyiv'
}, {
    value: 'Asia/Nicosia',
    label: '(+03:00) Asia/Nicosia'
}, {
    value: 'Asia/Famagusta',
    label: '(+03:00) Asia/Famagusta'
}, {
    value: 'W-SU',
    label: '(+03:00) W-SU'
}, {
    value: 'EET',
    label: '(+03:00) EET'
}, {
    value: 'Asia/Hebron',
    label: '(+03:00) Asia/Hebron'
}, {
    value: 'Asia/Amman',
    label: '(+03:00) Asia/Amman'
}, {
    value: 'Europe/Nicosia',
    label: '(+03:00) Europe/Nicosia'
}, {
    value: 'Europe/Athens',
    label: '(+03:00) Europe/Athens'
}, {
    value: 'Iran',
    label: '(+03:30) Iran'
}, {
    value: 'Asia/Tehran',
    label: '(+03:30) Asia/Tehran'
}, {
    value: 'Asia/Yerevan',
    label: '(+04:00) Asia/Yerevan'
}, {
    value: 'Etc/GMT-4',
    label: '(+04:00) Etc/GMT-4'
}, {
    value: 'Asia/Dubai',
    label: '(+04:00) Asia/Dubai'
}, {
    value: 'Indian/Reunion',
    label: '(+04:00) Indian/Reunion'
}, {
    value: 'Indian/Mauritius',
    label: '(+04:00) Indian/Mauritius'
}, {
    value: 'Europe/Saratov',
    label: '(+04:00) Europe/Saratov'
}, {
    value: 'Europe/Samara',
    label: '(+04:00) Europe/Samara'
}, {
    value: 'Indian/Mahe',
    label: '(+04:00) Indian/Mahe'
}, {
    value: 'Asia/Baku',
    label: '(+04:00) Asia/Baku'
}, {
    value: 'Asia/Muscat',
    label: '(+04:00) Asia/Muscat'
}, {
    value: 'Europe/Astrakhan',
    label: '(+04:00) Europe/Astrakhan'
}, {
    value: 'Asia/Tbilisi',
    label: '(+04:00) Asia/Tbilisi'
}, {
    value: 'Europe/Ulyanovsk',
    label: '(+04:00) Europe/Ulyanovsk'
}, {
    value: 'Asia/Kabul',
    label: '(+04:30) Asia/Kabul'
}, {
    value: 'Asia/Aqtau',
    label: '(+05:00) Asia/Aqtau'
}, {
    value: 'Etc/GMT-5',
    label: '(+05:00) Etc/GMT-5'
}, {
    value: 'Asia/Samarkand',
    label: '(+05:00) Asia/Samarkand'
}, {
    value: 'Asia/Karachi',
    label: '(+05:00) Asia/Karachi'
}, {
    value: 'Asia/Yekaterinburg',
    label: '(+05:00) Asia/Yekaterinburg'
}, {
    value: 'Asia/Dushanbe',
    label: '(+05:00) Asia/Dushanbe'
}, {
    value: 'Indian/Maldives',
    label: '(+05:00) Indian/Maldives'
}, {
    value: 'Asia/Oral',
    label: '(+05:00) Asia/Oral'
}, {
    value: 'Asia/Tashkent',
    label: '(+05:00) Asia/Tashkent'
}, {
    value: 'Antarctica/Mawson',
    label: '(+05:00) Antarctica/Mawson'
}, {
    value: 'Asia/Qyzylorda',
    label: '(+05:00) Asia/Qyzylorda'
}, {
    value: 'Asia/Aqtobe',
    label: '(+05:00) Asia/Aqtobe'
}, {
    value: 'Asia/Ashkhabad',
    label: '(+05:00) Asia/Ashkhabad'
}, {
    value: 'Asia/Ashgabat',
    label: '(+05:00) Asia/Ashgabat'
}, {
    value: 'Asia/Atyrau',
    label: '(+05:00) Asia/Atyrau'
}, {
    value: 'Indian/Kerguelen',
    label: '(+05:00) Indian/Kerguelen'
}, {
    value: 'Asia/Kolkata',
    label: '(+05:30) Asia/Kolkata'
}, {
    value: 'Asia/Colombo',
    label: '(+05:30) Asia/Colombo'
}, {
    value: 'Asia/Calcutta',
    label: '(+05:30) Asia/Calcutta'
}, {
    value: 'Asia/Kathmandu',
    label: '(+05:45) Asia/Kathmandu'
}, {
    value: 'Asia/Katmandu',
    label: '(+05:45) Asia/Katmandu'
}, {
    value: 'Asia/Kashgar',
    label: '(+06:00) Asia/Kashgar'
}, {
    value: 'Etc/GMT-6',
    label: '(+06:00) Etc/GMT-6'
}, {
    value: 'Asia/Almaty',
    label: '(+06:00) Asia/Almaty'
}, {
    value: 'Asia/Dacca',
    label: '(+06:00) Asia/Dacca'
}, {
    value: 'Asia/Omsk',
    label: '(+06:00) Asia/Omsk'
}, {
    value: 'Asia/Dhaka',
    label: '(+06:00) Asia/Dhaka'
}, {
    value: 'Indian/Chagos',
    label: '(+06:00) Indian/Chagos'
}, {
    value: 'Asia/Qostanay',
    label: '(+06:00) Asia/Qostanay'
}, {
    value: 'Asia/Bishkek',
    label: '(+06:00) Asia/Bishkek'
}, {
    value: 'Antarctica/Vostok',
    label: '(+06:00) Antarctica/Vostok'
}, {
    value: 'Asia/Urumqi',
    label: '(+06:00) Asia/Urumqi'
}, {
    value: 'Asia/Thimbu',
    label: '(+06:00) Asia/Thimbu'
}, {
    value: 'Asia/Thimphu',
    label: '(+06:00) Asia/Thimphu'
}, {
    value: 'Asia/Yangon',
    label: '(+06:30) Asia/Yangon'
}, {
    value: 'Asia/Rangoon',
    label: '(+06:30) Asia/Rangoon'
}, {
    value: 'Indian/Cocos',
    label: '(+06:30) Indian/Cocos'
}, {
    value: 'Asia/Pontianak',
    label: '(+07:00) Asia/Pontianak'
}, {
    value: 'Etc/GMT-7',
    label: '(+07:00) Etc/GMT-7'
}, {
    value: 'Asia/Phnom_Penh',
    label: '(+07:00) Asia/Phnom_Penh'
}, {
    value: 'Asia/Novosibirsk',
    label: '(+07:00) Asia/Novosibirsk'
}, {
    value: 'Antarctica/Davis',
    label: '(+07:00) Antarctica/Davis'
}, {
    value: 'Asia/Tomsk',
    label: '(+07:00) Asia/Tomsk'
}, {
    value: 'Asia/Jakarta',
    label: '(+07:00) Asia/Jakarta'
}, {
    value: 'Asia/Barnaul',
    label: '(+07:00) Asia/Barnaul'
}, {
    value: 'Indian/Christmas',
    label: '(+07:00) Indian/Christmas'
}, {
    value: 'Asia/Ho_Chi_Minh',
    label: '(+07:00) Asia/Ho_Chi_Minh'
}, {
    value: 'Asia/Hovd',
    label: '(+07:00) Asia/Hovd'
}, {
    value: 'Asia/Bangkok',
    label: '(+07:00) Asia/Bangkok'
}, {
    value: 'Asia/Vientiane',
    label: '(+07:00) Asia/Vientiane'
}, {
    value: 'Asia/Novokuznetsk',
    label: '(+07:00) Asia/Novokuznetsk'
}, {
    value: 'Asia/Krasnoyarsk',
    label: '(+07:00) Asia/Krasnoyarsk'
}, {
    value: 'Asia/Saigon',
    label: '(+07:00) Asia/Saigon'
}, {
    value: 'Asia/Kuching',
    label: '(+08:00) Asia/Kuching'
}, {
    value: 'Asia/Chungking',
    label: '(+08:00) Asia/Chungking'
}, {
    value: 'Etc/GMT-8',
    label: '(+08:00) Etc/GMT-8'
}, {
    value: 'Australia/Perth',
    label: '(+08:00) Australia/Perth'
}, {
    value: 'Asia/Macao',
    label: '(+08:00) Asia/Macao'
}, {
    value: 'Asia/Macau',
    label: '(+08:00) Asia/Macau'
}, {
    value: 'Asia/Choibalsan',
    label: '(+08:00) Asia/Choibalsan'
}, {
    value: 'Asia/Shanghai',
    label: '(+08:00) Asia/Shanghai'
}, {
    value: 'Asia/Ulan_Bator',
    label: '(+08:00) Asia/Ulan_Bator'
}, {
    value: 'Asia/Chongqing',
    label: '(+08:00) Asia/Chongqing'
}, {
    value: 'Asia/Ulaanbaatar',
    label: '(+08:00) Asia/Ulaanbaatar'
}, {
    value: 'Asia/Taipei',
    label: '(+08:00) Asia/Taipei'
}, {
    value: 'Asia/Manila',
    label: '(+08:00) Asia/Manila'
}, {
    value: 'PRC',
    label: '(+08:00) PRC'
}, {
    value: 'Asia/Ujung_Pandang',
    label: '(+08:00) Asia/Ujung_Pandang'
}, {
    value: 'Asia/Harbin',
    label: '(+08:00) Asia/Harbin'
}, {
    value: 'Singapore',
    label: '(+08:00) Singapore'
}, {
    value: 'Asia/Brunei',
    label: '(+08:00) Asia/Brunei'
}, {
    value: 'Australia/West',
    label: '(+08:00) Australia/West'
}, {
    value: 'Asia/Hong_Kong',
    label: '(+08:00) Asia/Hong_Kong'
}, {
    value: 'Asia/Makassar',
    label: '(+08:00) Asia/Makassar'
}, {
    value: 'Hongkong',
    label: '(+08:00) Hongkong'
}, {
    value: 'Asia/Kuala_Lumpur',
    label: '(+08:00) Asia/Kuala_Lumpur'
}, {
    value: 'Asia/Irkutsk',
    label: '(+08:00) Asia/Irkutsk'
}, {
    value: 'Asia/Singapore',
    label: '(+08:00) Asia/Singapore'
}, {
    value: 'Australia/Eucla',
    label: '(+08:45) Australia/Eucla'
}, {
    value: 'Etc/GMT-9',
    label: '(+09:00) Etc/GMT-9'
}, {
    value: 'Pacific/Palau',
    label: '(+09:00) Pacific/Palau'
}, {
    value: 'Asia/Chita',
    label: '(+09:00) Asia/Chita'
}, {
    value: 'Asia/Dili',
    label: '(+09:00) Asia/Dili'
}, {
    value: 'Asia/Jayapura',
    label: '(+09:00) Asia/Jayapura'
}, {
    value: 'Asia/Yakutsk',
    label: '(+09:00) Asia/Yakutsk'
}, {
    value: 'Asia/Pyongyang',
    label: '(+09:00) Asia/Pyongyang'
}, {
    value: 'ROK',
    label: '(+09:00) ROK'
}, {
    value: 'Asia/Seoul',
    label: '(+09:00) Asia/Seoul'
}, {
    value: 'Asia/Khandyga',
    label: '(+09:00) Asia/Khandyga'
}, {
    value: 'Japan',
    label: '(+09:00) Japan'
}, {
    value: 'Asia/Tokyo',
    label: '(+09:00) Asia/Tokyo'
}, {
    value: 'Australia/North',
    label: '(+09:30) Australia/North'
}, {
    value: 'Australia/Yancowinna',
    label: '(+09:30) Australia/Yancowinna'
}, {
    value: 'Australia/Adelavaluee',
    label: '(+09:30) Australia/Adelavaluee'
}, {
    value: 'Australia/Broken_Hill',
    label: '(+09:30) Australia/Broken_Hill'
}, {
    value: 'Australia/South',
    label: '(+09:30) Australia/South'
}, {
    value: 'Australia/Darwin',
    label: '(+09:30) Australia/Darwin'
}, {
    value: 'Australia/Hobart',
    label: '(+10:00) Australia/Hobart'
}, {
    value: 'Pacific/Yap',
    label: '(+10:00) Pacific/Yap'
}, {
    value: 'Australia/Tasmania',
    label: '(+10:00) Australia/Tasmania'
}, {
    value: 'Pacific/Port_Moresby',
    label: '(+10:00) Pacific/Port_Moresby'
}, {
    value: 'Australia/ACT',
    label: '(+10:00) Australia/ACT'
}, {
    value: 'Australia/Victoria',
    label: '(+10:00) Australia/Victoria'
}, {
    value: 'Antarctica/Macquarie',
    label: '(+10:00) Antarctica/Macquarie'
}, {
    value: 'Pacific/Chuuk',
    label: '(+10:00) Pacific/Chuuk'
}, {
    value: 'Australia/Queensland',
    label: '(+10:00) Australia/Queensland'
}, {
    value: 'Australia/Canberra',
    label: '(+10:00) Australia/Canberra'
}, {
    value: 'Australia/Currie',
    label: '(+10:00) Australia/Currie'
}, {
    value: 'Pacific/Guam',
    label: '(+10:00) Pacific/Guam'
}, {
    value: 'Pacific/Truk',
    label: '(+10:00) Pacific/Truk'
}, {
    value: 'Australia/NSW',
    label: '(+10:00) Australia/NSW'
}, {
    value: 'Asia/Vladivostok',
    label: '(+10:00) Asia/Vladivostok'
}, {
    value: 'Pacific/Saipan',
    label: '(+10:00) Pacific/Saipan'
}, {
    value: 'Antarctica/DumontDUrville',
    label: '(+10:00) Antarctica/DumontDUrville'
}, {
    value: 'Australia/Sydney',
    label: '(+10:00) Australia/Sydney'
}, {
    value: 'Australia/Brisbane',
    label: '(+10:00) Australia/Brisbane'
}, {
    value: 'Etc/GMT-10',
    label: '(+10:00) Etc/GMT-10'
}, {
    value: 'Asia/Ust-Nera',
    label: '(+10:00) Asia/Ust-Nera'
}, {
    value: 'Australia/Melbourne',
    label: '(+10:00) Australia/Melbourne'
}, {
    value: 'Australia/Lindeman',
    label: '(+10:00) Australia/Lindeman'
}, {
    value: 'Australia/Lord_Howe',
    label: '(+10:30) Australia/Lord_Howe'
}, {
    value: 'Australia/LHI',
    label: '(+10:30) Australia/LHI'
}, {
    value: 'Pacific/Ponape',
    label: '(+11:00) Pacific/Ponape'
}, {
    value: 'Pacific/Bougainville',
    label: '(+11:00) Pacific/Bougainville'
}, {
    value: 'Antarctica/Casey',
    label: '(+11:00) Antarctica/Casey'
}, {
    value: 'Pacific/Pohnpei',
    label: '(+11:00) Pacific/Pohnpei'
}, {
    value: 'Pacific/Efate',
    label: '(+11:00) Pacific/Efate'
}, {
    value: 'Pacific/Norfolk',
    label: '(+11:00) Pacific/Norfolk'
}, {
    value: 'Asia/Magadan',
    label: '(+11:00) Asia/Magadan'
}, {
    value: 'Pacific/Kosrae',
    label: '(+11:00) Pacific/Kosrae'
}, {
    value: 'Asia/Sakhalin',
    label: '(+11:00) Asia/Sakhalin'
}, {
    value: 'Pacific/Noumea',
    label: '(+11:00) Pacific/Noumea'
}, {
    value: 'Etc/GMT-11',
    label: '(+11:00) Etc/GMT-11'
}, {
    value: 'Asia/Srednekolymsk',
    label: '(+11:00) Asia/Srednekolymsk'
}, {
    value: 'Pacific/Guadalcanal',
    label: '(+11:00) Pacific/Guadalcanal'
}, {
    value: 'Pacific/Kwajalein',
    label: '(+12:00) Pacific/Kwajalein'
}, {
    value: 'Antarctica/McMurdo',
    label: '(+12:00) Antarctica/McMurdo'
}, {
    value: 'Pacific/Wallis',
    label: '(+12:00) Pacific/Wallis'
}, {
    value: 'Pacific/Fiji',
    label: '(+12:00) Pacific/Fiji'
}, {
    value: 'Pacific/Funafuti',
    label: '(+12:00) Pacific/Funafuti'
}, {
    value: 'Pacific/Nauru',
    label: '(+12:00) Pacific/Nauru'
}, {
    value: 'Kwajalein',
    label: '(+12:00) Kwajalein'
}, {
    value: 'NZ',
    label: '(+12:00) NZ'
}, {
    value: 'Pacific/Wake',
    label: '(+12:00) Pacific/Wake'
}, {
    value: 'Antarctica/South_Pole',
    label: '(+12:00) Antarctica/South_Pole'
}, {
    value: 'Pacific/Tarawa',
    label: '(+12:00) Pacific/Tarawa'
}, {
    value: 'Pacific/Auckland',
    label: '(+12:00) Pacific/Auckland'
}, {
    value: 'Asia/Kamchatka',
    label: '(+12:00) Asia/Kamchatka'
}, {
    value: 'Etc/GMT-12',
    label: '(+12:00) Etc/GMT-12'
}, {
    value: 'Asia/Anadyr',
    label: '(+12:00) Asia/Anadyr'
}, {
    value: 'Pacific/Majuro',
    label: '(+12:00) Pacific/Majuro'
}, {
    value: 'NZ-CHAT',
    label: '(+12:45) NZ-CHAT'
}, {
    value: 'Pacific/Chatham',
    label: '(+12:45) Pacific/Chatham'
}, {
    value: 'Pacific/Fakaofo',
    label: '(+13:00) Pacific/Fakaofo'
}, {
    value: 'Pacific/Enderbury',
    label: '(+13:00) Pacific/Enderbury'
}, {
    value: 'Pacific/Apia',
    label: '(+13:00) Pacific/Apia'
}, {
    value: 'Pacific/Kanton',
    label: '(+13:00) Pacific/Kanton'
}, {
    value: 'Pacific/Tongatapu',
    label: '(+13:00) Pacific/Tongatapu'
}, {
    value: 'Etc/GMT-13',
    label: '(+13:00) Etc/GMT-13'
}, {
    value: 'Pacific/Kiritimati',
    label: '(+14:00) Pacific/Kiritimati'
}, {
    value: 'Etc/GMT-14',
    label: '(+14:00) Etc/GMT-14'
}]
