import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "components/Layout";
import PortfolioInstantPage from "./pages/PortfolioInstantPage";
import OverviewPage from "pages/OverviewPage";
import { ProfilePage } from "pages/ProfilePage";
import SettingsPage from "pages/SettingsPage";
import TermsOfServicesPage from "pages/TermsOfServicesPage";
import PrivacyPolicyPage from "pages/PrivacyPolicyPage";
import TradeBasicPage from "pages/TradeBasicPage";
import CurrencyContext from "contexts/CurrencyContext";
import PortfolioPerformancePage from "pages/PortfolioPerformancePage";
import LoginPage from "pages/LoginPage";
import WelcomePage from "pages/WelcomePage";
import WelcomePageV2 from "pages/WelcomePageV2";
import RegistrationPage from "pages/RegistrationPage";
import { AuthProvider } from "contexts/AuthContext";
import ProtectedRoute from "components/ProtectedRoute";
import AnonymousRoute from "components/AnonymousRoute";
import NotFound from 'pages/NotFoundPage';
import TourManager from "components/TourManager";

function App() {
    const [selectedCurrency, setSelectedCurrency] = useState("USDT");

    return (
        <CurrencyContext.Provider value={{ selectedCurrency, setSelectedCurrency }}>
            <AuthProvider>
                    <Router>
                        <TourManager>
                            <Layout>
                                <Routes>
                                    <Route>
                                        <Route path="/terms-of-services" element={<TermsOfServicesPage />} />
                                        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                                    </Route>
                                    <Route element={<AnonymousRoute />}>
                                        {/*TODO [MeAn] [YYL] anonymous/authenticated check on "/" page */}
                                        <Route path="/login" element={<LoginPage />} />
                                        <Route path="/signup" element={<RegistrationPage />} />
                                        <Route path="/v2" element={<WelcomePageV2 />} />
                                        <Route path="/" element={<WelcomePage />} />
                                    </Route>
                                    <Route element={<ProtectedRoute />}>
                                        <Route path="/profile" element={<ProfilePage />} />
                                        <Route path="/settings" element={<SettingsPage />} />
                                        <Route path="/portfolio/instant" element={<PortfolioInstantPage />} />
                                        <Route path="/portfolio/performance" element={<PortfolioPerformancePage />} />
                                        <Route path="/trade/basic" element={<TradeBasicPage />} />
                                    </Route>
                                    <Route path="*" element={<NotFound />} />
                                </Routes>
                            </Layout>
                        </TourManager>
                    </Router>
            </AuthProvider>
        </CurrencyContext.Provider>
    );
}

export default App;
