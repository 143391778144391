import React, { useState, useEffect } from "react";
import * as Sentry from '@sentry/react';
import { Helmet } from 'react-helmet-async';
import Select from "react-select";
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { AiOutlineDelete } from 'react-icons/ai';
import { getConfigsData, deleteConfigsData, getCreationConfigsData, addAPIKeys } from 'api/apis';
import { appConstants } from 'constants/app.constants';
import binanceIcon from 'assets/svg/binance.svg';
import bitfinexIcon from 'assets/svg/bitfinex.svg';
import krakenIcon from 'assets/svg/kraken.svg';
import gateioIcon from 'assets/svg/gateio.svg';
import stringUtils from "utils/stringUtils";
import { useTour } from "@reactour/tour";
import { useLocation } from "react-router-dom";

interface OptionType {
    value: string;
    label: string;
}

interface ConfigOptionDetail {
    options: string[];
    contextsKeys: {
        [key: string]: string[];
    };
}

interface Configs {
    options: {
        [exchange: string]: ConfigOptionDetail;
    };
}

const exchangeIcons: { [key: string]: string } = {
    BINANCE: binanceIcon,
    KRAKEN: krakenIcon,
    GATEIO: gateioIcon,
    BITFINEX: bitfinexIcon,
};

const SettingsPage = () => {
    const [show, setShow] = useState(false);
    const [exchange, setExchange] = useState<OptionType | null>(null);
    const [exchanges, setExchanges] = useState<OptionType[]>([]);
    const [exchangeName, setExchangeName] = useState('');
    const [configs, setConfigs] = useState<Configs | null>(null);
    const [options, setOptions] = useState<OptionType[]>([]);
    const [option, setOption] = useState<OptionType | null>(null);
    const [selectedContexts, setSelectedContexts] = useState<string[]>([]);
    const [values, setValues] = useState<[string, any][]>([]);
    const [keys, setKeys] = useState<{ [context: string]: { key: string; secret: string } }>({});

    const [error, setError] = useState<string | null>(null);

    const title = stringUtils.getHtmlTitle(appConstants.TITLES.SETTINGS);
    const { setIsOpen, setCurrentStep } = useTour();
    const { state } = useLocation();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getConfigsData();
                const configsData = await getCreationConfigsData();
                setExchanges(Object.keys(configsData.options).map(exchange => ({ label: exchange, value: exchange })));
                setConfigs(configsData);
                setValues(data.values);
            } catch (error) {
                Sentry.captureException(error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (state?.step !== undefined) {
            setCurrentStep(state.step);
        }
    }, [state, setCurrentStep, setIsOpen]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSave = async () => {
        if (!exchange && !exchangeName) {
            setError("Exchange and unique name are required.");
            return;
        }

        if (!option) {
            setError("Exchange option is required.");
            return;
        }

        let missingFields: string[] = [];

        selectedContexts.forEach((context) => {
            if (!keys[context]?.key) {
                missingFields.push(`${context} Key`);
            }
            if (!keys[context]?.secret) {
                missingFields.push(`${context} Secret`);
            }
        });

        if (missingFields.length > 0) {
            setError(`Missing fields: ${missingFields.join(', ')}`);
            return;
        }

        const apiKeys = selectedContexts.map(context => ({
            type: context,
            apiKey: keys[context]?.key || '',
        }));

        const exchangeData = {
            exchangeType: exchange?.value,
            name: exchangeName,
            apiKeys,
        };

        try {
            await addAPIKeys(exchangeData)
            await refreshConfigs();
            resetForm();
            handleClose();
        } catch (err) {
            setError("Failed to save exchange data.");
            Sentry.captureException(err);
        }
    };

    const refreshConfigs = async () => {
        try {
            const updatedData = await getConfigsData();
            setValues(updatedData.values);

            const updatedOptions = updatedData.options || {};
            setOptions(updatedOptions.map((opt: string) => ({ label: opt, value: opt })));
        } catch (error) {
            Sentry.captureException(error);
        }
    };

    const handleExchangeChange = (selectedOption: OptionType | null) => {
        setExchange(selectedOption);
        setOption(null);
        setSelectedContexts([]);

        if (selectedOption && configs?.options) {
            const selectedOptions = configs.options[selectedOption.value]?.options || [];
            setOptions(selectedOptions.map((opt: string) => ({ label: opt, value: opt })));
        } else {
            setOptions([]);
        }
    };

    const handleOptionChange = (selectedOption: OptionType | null) => {
        setOption(selectedOption);

        if (selectedOption && exchange && configs) {
            const exchangeConfig = configs.options[exchange.value];
            const contexts = exchangeConfig.contextsKeys[selectedOption.value] || [];
            setSelectedContexts(contexts);
        } else {
            setSelectedContexts([]);
        }
    };

    const handleDelete = async (exchange: string) => {
        try {
            await deleteConfigsData(exchange);
            await refreshConfigs();
        } catch (err) {
            setError("Failed to delete exchange.");
            Sentry.captureException(err);
        }
    };

    const resetForm = () => {
        setExchange(null);
        setOption(null);
        setKeys({});
        setExchangeName('');
        setSelectedContexts([]);
        setError('');
    };

    return (
        <div className="card settings mx-2">
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <div className="card-header d-flex justify-content-between align-items-center">
                <h5 className="card-title mb-0">API Keys</h5>
                <button className="button btn btn-primary d-flex align-items-center step-2" onClick={handleShow}>Add API Keys</button>
            </div>
            <div className={`card-body ${Object.entries(values).length === 0 ? 'settings__empty-state' : ''}`}>
                {Object.entries(values).length === 0 && <p className="text-center">No API Keys Added</p>}
                {Object.entries(values).map(([exchangeName, exchangeData]) => (
                    <Accordion className="settings__accordion mb-2" key={exchangeName} alwaysOpen>
                        <Accordion.Item className="settings__accordion-item" eventKey="0">
                            <Accordion.Header>
                                <div className="d-flex justify-content-between align-items-center w-100">
                                    <div className="d-flex align-items-center gap-2">
                                        <img width="15" height="15" src={exchangeIcons[exchangeName]} alt={`${exchangeName} icon`} />
                                        {exchangeName}
                                    </div>
                                    <OverlayTrigger placement="top" overlay={<Tooltip>Delete {exchangeName} exchange</Tooltip>}>
                                        <span>
                                            <AiOutlineDelete onClick={(event) => {
                                                event.stopPropagation();
                                                handleDelete(exchangeName);
                                            }} />
                                        </span>
                                    </OverlayTrigger>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                {Object.entries(exchangeData).map(([configName, configData]) => (
                                    configData.apiKeys && (
                                        <div key={configName}>
                                            <p className="settings__exchange-name p-2">{configName}</p>
                                            {configData.apiKeys.map((apiKey: any) => (
                                                <div className="settings__key-type d-flex align-items-center p-3" key={apiKey.type}>
                                                    <span className="w-25">{apiKey.type}</span>
                                                    <span className="settings__key mx-2">{apiKey.maskedApiKey}</span>
                                                </div>
                                            ))}
                                        </div>
                                    )
                                ))}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                ))}
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add API Keys</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <span className="settings__input-label">Exchange type</span>
                        <Select
                            className="w-100 mb-3"
                            options={exchanges}
                            placeholder="Select exchange"
                            value={exchange}
                            onChange={handleExchangeChange}
                        />
                        {exchange && <>
                            <span className="settings__input-label">Exchange Name</span>
                            <input
                                type="text"
                                className="form-control mb-3"
                                placeholder="Enter unique exchange name"
                                value={exchangeName}
                                onChange={(e) => setExchangeName(e.target.value)}
                            />
                        </>}
                        {exchangeName &&
                            <div>
                                <span className="settings__input-label">Exchange option</span>
                                <Select
                                    className="w-100 mb-3"
                                    options={options}
                                    placeholder="Select option"
                                    value={option}
                                    onChange={handleOptionChange}
                                />
                            </div>
                        }
                        {selectedContexts.map((context) => (
                            <React.Fragment key={context}>
                                <label className="settings__input-label" htmlFor={`${context.toLowerCase()}Key`}>
                                    {context} Key
                                </label>
                                <input
                                    type="text"
                                    id={`${context.toLowerCase()}Key`}
                                    onChange={(e) =>
                                        setKeys((prevKeys) => ({
                                            ...prevKeys,
                                            [context]: {
                                                ...(prevKeys[context] || {}),
                                                key: e.target.value,
                                            },
                                        }))
                                    }
                                    value={keys[context]?.key || ''}
                                    className="form-control mb-3"
                                    placeholder={`${context} Key`}
                                />
                                <label className="settings__input-label" htmlFor={`${context.toLowerCase()}Secret`}>
                                    {context} Secret
                                </label>
                                <input
                                    type="text"
                                    id={`${context.toLowerCase()}Secret`}
                                    onChange={(e) =>
                                        setKeys((prevKeys) => ({
                                            ...prevKeys,
                                            [context]: {
                                                ...(prevKeys[context] || {}),
                                                secret: e.target.value,
                                            },
                                        }))
                                    }
                                    value={keys[context]?.secret || ''}
                                    className="form-control mb-3"
                                    placeholder={`${context} Secret`}
                                />
                            </React.Fragment>
                        ))}
                        {error && <div className="text-danger mt-2">{error}</div>}
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleClose}>
                        Close
                    </Button>
                    <button className="button btn btn-primary d-flex align-items-center" onClick={handleSave}>
                    Save Changes
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default SettingsPage;
